import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useData } from '../../DataProvider';
import MiniPaginationCard from './MiniPaginationCard';
import { getSelectedItemById } from '../../../util/util';

export default function FabricHorizontalPagination({
  hotspotIndex,
  hotspotId,
  todosPerPage,
  resetCounter,
  setReset,
  isWatchList = false,
  direction,
}) {
  const { cardsMap, watchMap, selectedItems, selectedItemsRight, filterItems } = useData();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1224px)' });
  const isDesktop = useMediaQuery({ minWidth: 1300 });

  const [allProductsArray, setAllProducts] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    let allProducts = [];

    if (isWatchList) {
      watchMap.forEach((value) => {
        allProducts.push(value);
      });
    } else {
      allProducts = cardsMap.filter((entry) => entry.product.post_title.indexOf(''.replace(/ /g, '')) !== -1);
    }

    if (!isWatchList) {
      if (filterItems.colorFilter.length > 0) {
        allProducts = allProducts.filter((entry) => {
          return filterItems.colorFilter.includes(entry.product.color_primary);
        });
      }

      if (filterItems.patternFilter.length > 0) {
        allProducts = allProducts.filter((entry) => {
          if (entry.product.pattern.includes(',')) {
            const ret = entry.product.pattern.split(',').map((item) => {
              return filterItems.patternFilter.includes(item);
            });
            return ret.includes(true);
          }
          return filterItems.patternFilter.includes(entry.product.pattern);
        });
      }

      if (filterItems.specialAttributesFilter.length > 0) {
        allProducts = allProducts.filter((entry) => {
          return filterItems.specialAttributesFilter.includes(entry.product.attribute_set);
        });
      }

      if (filterItems.collectionFilter.length > 0) {
        allProducts = allProducts.filter((entry) => {
          return filterItems.collectionFilter.includes(entry.product.collection);
        });
      }

      if (filterItems.designFilter.length > 0) {
        allProducts = allProducts.filter((entry) => {
          return filterItems.designFilter.includes(entry.product.design_line);
        });
      }

      if (hotspotId) {
        allProducts = allProducts.filter((entry) => {
          return entry.subgroupId === hotspotId;
        });
      }
    }
    setAllProducts(allProducts);
    if (resetCounter) {
      setCurrentPage(1);
      setReset(false);
    }

    const initCurrentPage = () => {
      if (hotspotId) {
        let indexFound;
        if (direction === 'left') {
          indexFound = allProducts.findIndex(
            (el) => el.product.post_title === getSelectedItemById(selectedItems, hotspotId).fabric.post_title,
          );
        } else if (direction === 'right') {
          indexFound = allProducts.findIndex(
            (el) => el.product.post_title === getSelectedItemById(selectedItemsRight, hotspotId).fabric.post_title,
          );
        }
        const value = indexFound && indexFound !== -1 ? Math.floor(indexFound / todosPerPage) + 1 : 1;
        setCurrentPage(value);
      }
      return 1;
    };
    initCurrentPage();
  }, [
    cardsMap,
    direction,
    filterItems.collectionFilter,
    filterItems.colorFilter,
    filterItems.designFilter,
    filterItems.patternFilter,
    filterItems.specialAttributesFilter,
    hotspotId,
    isWatchList,
    resetCounter,
    selectedItems,
    selectedItemsRight,
    setReset,
    todosPerPage,
    watchMap,
  ]);

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentElements = allProductsArray ? allProductsArray.slice(indexOfFirstTodo, indexOfLastTodo) : [];

  const buttonStyles = {
    color: ' #414141',
    margin: isDesktop ? 0 : -20,
    padding: 0,
  };

  function next() {
    setCurrentPage(currentPage + 1);
  }

  function previous() {
    setCurrentPage(currentPage - 1);
  }

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          {indexOfFirstTodo > 0 ? (
            <Button onClick={previous} style={buttonStyles}>
              <ArrowBackIosIcon />
              {!isMobileScreen
                ? indexOfLastTodo >= allProductsArray && allProductsArray.length > 0
                  ? allProductsArray.length
                  : `${indexOfLastTodo} von ${allProductsArray.length}`
                : ''}
            </Button>
          ) : null}
        </Grid>
        {currentElements.map((cardItem) => (
          <Grid item key={cardItem.product.id}>
            <MiniPaginationCard
              hotspotIndex={hotspotIndex}
              hotspotId={hotspotId}
              cardInfos={cardItem}
              id={cardItem.product.id}
              direction={direction}
            />
          </Grid>
        ))}
        <Grid item xs={1}>
          <div>
            {allProductsArray && indexOfLastTodo < allProductsArray.length ? (
              <Button onClick={next} style={buttonStyles}>
                {!isMobileScreen && t('next_btn')}
                <ArrowForwardIosIcon />
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

FabricHorizontalPagination.propTypes = {
  resetCounter: PropTypes.number,
  setReset: PropTypes.func,
  isWatchList: PropTypes.bool,
  todosPerPage: PropTypes.number.isRequired,
  hotspotIndex: PropTypes.number,
  direction: PropTypes.string,
};

FabricHorizontalPagination.defaultProps = {
  resetCounter: null,
  setReset: null,
  isWatchList: false,
  hotspotIndex: undefined,
  direction: 'left',
};

import React, { useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { useDrag, useDrop } from 'react-dnd';
import CardActionArea from '@material-ui/core/CardActionArea';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useData } from '../DataProvider';
import ItemTypes from './ItemTypes';
import { closeFabricViewDialog, openFabricDetailsDialog, openMiniView } from '../Subjects/subjects';
import { getHotspotIndexBySubgroupId, getSelectedItemById } from '../../util/util';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 142,
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
    margin: 'auto',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  },
  media: {
    paddingTop: '53.25%',
  },
  content: {
    textAlign: 'left',
    padding: '5px 7px 7px 9px',
    minHeight: 100,
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {
      marginLeft: -theme.spacing(1),
    },
  },
  actionArea: {
    '&:hover focusHighlight': {
      opacity: 0,
    },
    borderColor: 'red',
  },
  cardBorder: {
    boxShadow: (param) => `0px 0px 0px 2px ${param.selectColor} inset`,
  },
}));

export default function PaginationCard({
  cardInfos,
  id,
  index,
  moveCard,
  isDraggable,
  isArticleSearch,
  isCompareMode = false,
  subgroupId,
  isWatchlist,
  isGardenFurniture,
}) {
  const {
    watchMap,
    updateCard,
    selectedItems,
    setSelectedItems,
    selectedItemsPerIndex,
    setSelectedItemsPerIndex,
    selectedItemsPerIndexRight,
    setSelectedItemsPerIndexRight,
    jsonData,
    selectedItemsRight,
    setSelectedItemsRight,
    setSelectedSearchItems,
    selectColor,
    selectedSceneId,
    selectedSceneIndex,
    allScenes,
  } = useData();
  const classes = useStyles({ selectColor });
  const isOnWatchMap = !!watchMap.get(id);
  const [isFavorite, setFavorite] = useState(isOnWatchMap);
  const headerText = cardInfos.product.customer_set_alt_article_number
    ? cardInfos.product.customer_set_alt_article_number
    : cardInfos.product.post_title;

  let collectionText;
  let designText;
  let isSelected = false;

  const fabricImageURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_FOLDER}${cardInfos.product.post_title}.jpg`;

  const currentScene = jsonData.scenes.find((el) => el.scene.id === selectedSceneId);
  const hotspotIndex = getHotspotIndexBySubgroupId(subgroupId, currentScene);

  if (hotspotIndex !== undefined) {
    if (isCompareMode) {
      isSelected =
        cardInfos.product.post_title === getSelectedItemById(selectedItemsRight, subgroupId).fabric.post_title;
    } else {
      isSelected = subgroupId
        ? cardInfos.product.post_title === getSelectedItemById(selectedItems, subgroupId).fabric.post_title
        : false;
    }
  }

  if (isGardenFurniture) {
    if (cardInfos.product.collection !== '') {
      const collections = cardInfos.product.collection.split(',');

      const foundCollection = jsonData.collections.find((el) => el.id === Number(collections[0]));
      if (foundCollection) {
        collectionText = foundCollection.post_title;
      }

      const foundDesign = jsonData.design_lines.find((el) => el.id === Number(collections[1]));
      if (foundDesign) {
        designText = foundDesign.post_title;
      }
    }
  } else {
    const foundCollection = jsonData.collections.find((el) => el.id === Number(cardInfos.product.collection));
    if (foundCollection) {
      collectionText = foundCollection.post_title;
    }

    const foundDesign = jsonData.design_lines.find((el) => el.id === Number(cardInfos.product.design_line));
    if (foundDesign) {
      designText = foundDesign.post_title;
    }
  }

  const setItemFavorite = () => {
    setFavorite(!isFavorite);

    const newCardInfo = cardInfos;
    newCardInfo.isFavorite = !isFavorite;
    updateCard(id, newCardInfo);
  };

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item) {
      const dragItem = item;
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      dragItem.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDraggable) {
    drag(drop(ref));
  }

  const selectFabric = () => {
    if (isCompareMode) {
      getSelectedItemById(selectedItemsRight, subgroupId).fabric = cardInfos.product;
      setSelectedItemsRight({ ...selectedItemsRight });

      selectedItemsPerIndexRight[hotspotIndex].fabric = cardInfos.product;
      selectedItemsPerIndexRight[hotspotIndex].subgroupId = subgroupId;
      setSelectedItemsPerIndexRight({ ...selectedItemsPerIndexRight });
    } else if (isArticleSearch || isWatchlist) {
      const selectedSearchItems = JSON.parse(JSON.stringify(selectedItems));

      const scene = allScenes[selectedSceneIndex];
      if (Object.values(scene.fabrics).includes(cardInfos.product.id)) {
        scene.scene.hotspots.forEach((hotspot) => {
          if (cardInfos.product.product_subgroup.includes(hotspot.product_group)) {
            selectedItemsPerIndex[hotspotIndex].fabric = cardInfos.product;
            selectedItemsPerIndex[hotspotIndex].subgroupId = subgroupId;

            getSelectedItemById(selectedSearchItems, hotspot.product_group).fabric = cardInfos.product;
            setSelectedSearchItems({ ...selectedSearchItems });
          }
        });
      } else {
        setSelectedSearchItems({ ...selectedSearchItems });
      }
    } else {
      getSelectedItemById(selectedItems, subgroupId).fabric = cardInfos.product;
      selectedItemsPerIndex[hotspotIndex].fabric = cardInfos.product;
      selectedItemsPerIndex[hotspotIndex].subgroupId = subgroupId;
      setSelectedItems({ ...selectedItems });
      setSelectedItemsPerIndex({ ...selectedItemsPerIndex });
    }

    if (!isWatchlist) {
      if (!isArticleSearch) {
        const items = {};
        items.index = hotspotIndex;
        items.productGroup = getSelectedItemById(selectedItems, subgroupId).subgroupId;
        openMiniView(items);
      }
    }

    openFabricDetailsDialog(isArticleSearch);
    closeFabricViewDialog();
  };
  return (
    <div>
      <Card ref={ref} className={clsx(classes.card, isSelected ? classes.cardBorder : null)}>
        <CardActionArea
          classes={{
            root: classes.actionArea,
          }}
        >
          <CardMedia className={classes.media} image={fabricImageURL} onClick={selectFabric} />
          <CardContent className={classes.content}>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography
                  className="MuiTypography--heading"
                  variant="subtitle1"
                  style={{ fontSize: '1rem', textTransform: 'capitalize' }}
                >
                  {`${headerText.substring(0, 3)} ${headerText.substring(3, 6)}`}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <IconButton
                  aria-label="like"
                  style={{ color: selectColor, padding: 6, marginTop: '-5px' }}
                  onClick={setItemFavorite}
                >
                  {isFavorite ? (
                    <FavoriteIcon style={{ color: selectColor }} />
                  ) : (
                    <FavoriteBorderIcon style={{ color: selectColor }} />
                  )}
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Typography className="MuiTypography--subheading" variant="caption">
                  {cardInfos.product.customer_set_alt_fabric_name
                    ? cardInfos.product.customer_set_alt_fabric_name
                    : cardInfos.product.fabric_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="MuiTypography--subheading" variant="caption">
                  {collectionText}
                  <br />
                  {designText}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

PaginationCard.propTypes = {
  cardInfos: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isDraggable: PropTypes.bool,
  moveCard: PropTypes.func,
  isArticleSearch: PropTypes.bool,
  isCompareMode: PropTypes.bool,
  isWatchlist: PropTypes.bool,
};

PaginationCard.defaultProps = {
  moveCard: null,
  isDraggable: null,
  isArticleSearch: false,
  isCompareMode: false,
  isWatchlist: false,
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import CardActionArea from '@material-ui/core/CardActionArea';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useData } from '../../DataProvider';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 'auto',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  media: {
    paddingTop: '44.25%',
  },
  content: {
    textAlign: 'left',
    padding: theme.spacing(1),
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {
      marginLeft: -theme.spacing(1),
    },
  },
  actionArea: {
    '&:hover focusHighlight': {
      opacity: 0,
    },
  },
}));

export default function ScenesPaginationCard({
  cardInfos,
  shouldSceneBeUpdated,
  updateScene,
  findSelectedId,
  localCardId,
  setLocalCardId,
}) {
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const { selectColor } = useData();
  const classes = useStyles({ selectColor });

  const imageUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${cardInfos.scene.sub_path}${cardInfos.scene.post_title}_preview.png`;
  const title = cardInfos.scene.scene_name;
  const text = cardInfos.product_type_slug;
  const { id } = cardInfos.scene;
  const currentSceneId = shouldSceneBeUpdated ? findSelectedId() : localCardId;
  const isSelected = currentSceneId === id;

  return (
    <div style={isSelected ? { border: selectColor, borderStyle: 'solid' } : null}>
      <Card
        style={{ width: !isPhone ? 210 : 120, height: !isPhone ? 150 : 100 }}
        className={clsx(classes.card)}
        onClick={() => {
          if (shouldSceneBeUpdated) {
            updateScene(id);
          } else {
            setLocalCardId(id);
          }
        }}
      >
        <CardActionArea
          classes={{
            root: classes.actionArea,
          }}
        >
          <CardMedia className={classes.media} image={imageUrl} />
          <CardContent className={classes.content}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <div>
                <Typography
                  className="MuiTypography--heading"
                  variant="body2"
                  gutterBottom
                  style={{ fontSize: isPhone ? '0.7rem' : '1rem', textTransform: 'capitalize' }}
                >
                  {title}
                </Typography>
              </div>
            </div>
            <Typography className="MuiTypography--subheading" variant="caption">
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

ScenesPaginationCard.propTypes = {
  cardInfos: PropTypes.instanceOf(Object).isRequired,
  shouldSceneBeUpdated: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  updateScene: PropTypes.func.isRequired,
  findSelectedId: PropTypes.number.isRequired,
  localCardId: PropTypes.number.isRequired,
  setLocalCardId: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import PaginationCard from './PaginationCard';
import { useData } from '../DataProvider';
import { getProductGroupById } from '../../util/util';

function generateCompactList(cardsMap, filter, updateCompactList) {
  const list = [];
  cardsMap.forEach((value, key) => {
    if (filter !== null) {
      const isMatching = filter(value, key);
      if (isMatching) list.push(`${value.product.title} ${value.product.product_type}`);
    }
  });
  updateCompactList(list);
}

export default function FabricPagination({
  filter,
  resetCounter,
  setReset,
  updateCompactList,
  numberofFabrics,
  hotspotId,
  searchBarValue,
  hotspotIndex,
  isArticleSearch,
  isSearchBar,
  isCompareMode,
  subgroupId,
}) {
  const { cardsMap, selectedItemsPerIndex, filterItems, jsonData, currentScene } = useData();
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const { t } = useTranslation();

  const isnum = /^\d+$/.test(searchBarValue.replace(/ /g, ''));
  const searchText = isnum ? searchBarValue.replace(/ /g, '') : searchBarValue;

  let allProducts = cardsMap.filter((entry) => entry.product.post_title.indexOf(searchText) !== -1);

  if (allProducts.length === 0) {
    const allAltProductsId = cardsMap.filter(
      (entry) => entry.product.customer_set_alt_article_number.indexOf(searchText) !== -1,
    );
    const allAltProductsName = cardsMap.filter(
      (entry) => entry.product.customer_set_alt_fabric_name.toLowerCase().indexOf(searchText) !== -1,
    );
    const allProductsName = cardsMap.filter(
      (entry) => entry.product.fabric_name.toLowerCase().indexOf(searchText) !== -1,
    );

    const collections = jsonData.collections.find((el) => el.post_title.toLowerCase().includes(searchText));
    let allCollectionProducts = [];
    if (collections) allCollectionProducts = cardsMap.filter((entry) => +entry.product.collection === collections.id);

    const designs = jsonData.design_lines.find((el) => el.post_title.toLowerCase().includes(searchText));
    let allDesignProducts = [];
    if (designs) allDesignProducts = cardsMap.filter((entry) => +entry.product.design_line === designs.id);

    const colors = jsonData.colors.find((el) => el.post_title.toLowerCase().includes(searchText));
    let allColorProducts = [];
    if (colors) allColorProducts = cardsMap.filter((entry) => +entry.product.color_primary === colors.id);

    allProducts = [
      ...allCollectionProducts,
      ...allDesignProducts,
      ...allColorProducts,
      ...allAltProductsId,
      ...allAltProductsName,
      ...allProductsName,
    ];
  }

  if (!isSearchBar) {
    if (filterItems.colorFilter.length > 0) {
      allProducts = allProducts.filter((entry) => {
        return filterItems.colorFilter.includes(entry.product.color_primary);
      });
    }

    if (filterItems.patternFilter.length > 0) {
      allProducts = allProducts.filter((entry) => {
        if (entry.product.pattern.includes(',')) {
          const ret = entry.product.pattern.split(',').map((item) => {
            return filterItems.patternFilter.includes(item);
          });
          return ret.includes(true);
        }
        return filterItems.patternFilter.includes(entry.product.pattern);
      });
    }

    if (filterItems.specialAttributesFilter.length > 0) {
      allProducts = allProducts.filter((entry) => {
        return filterItems.specialAttributesFilter.includes(entry.product.attribute_set);
      });
    }

    if (filterItems.collectionFilter.length > 0) {
      allProducts = allProducts.filter((entry) => {
        return filterItems.collectionFilter.includes(entry.product.collection);
      });
    }

    if (filterItems.designFilter.length > 0) {
      allProducts = allProducts.filter((entry) => {
        return filterItems.designFilter.includes(entry.product.design_line);
      });
    }
  }
  if (hotspotId && hotspotId !== '0') {
    allProducts = allProducts.filter((entry) => {
      return entry.subgroupId === hotspotId;
    });
  }

  allProducts = Object.values(currentScene.fabrics)
    .map((s) =>
      allProducts.find((elem) => {
        if (s === elem.product.id) {
          return elem;
        }
        return null;
      }),
    )
    .filter((elem) => elem !== undefined);

  const productGroup = getProductGroupById(currentScene.product_group, jsonData);

  const initCurrentPage = () => {
    if (hotspotIndex) {
      const indexFound = allProducts.findIndex(
        (el) => el.product.post_title === selectedItemsPerIndex[hotspotIndex].fabric.post_title,
      );
      return indexFound !== -1 ? Math.floor(indexFound / numberofFabrics) + 1 : 1;
    }
    return 1;
  };

  const [currentPage, setCurrentPage] = useState(initCurrentPage);

  if (updateCompactList) generateCompactList(cardsMap, filter, updateCompactList);

  const indexOfLastTodo = currentPage * numberofFabrics;
  const indexOfFirstTodo = indexOfLastTodo - numberofFabrics;
  allProducts = allProducts.filter((fabric) => (subgroupId !== undefined ? fabric.subgroupId === subgroupId : fabric));

  if (isArticleSearch) {
    const ids = allProducts.map((o) => o.product.id);
    const filteredProducts = allProducts.filter((item, index) => !ids.includes(item.product.id, index + 1));
    allProducts = filteredProducts;
  }

  const uniqueProducts = [...new Set(allProducts)];
  const currentElements = uniqueProducts.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderCards = currentElements.map((cardItem, index) => (
    <Grid item key={`${cardItem.product.id} ${index}`}>
      <PaginationCard
        key={cardItem.product.id}
        cardInfos={cardItem}
        id={cardItem.product.id}
        index={index}
        isArticleSearch={isArticleSearch}
        isCompareMode={isCompareMode}
        subgroupId={cardItem.subgroupId}
        isGardenFurniture={productGroup.isGardenFurniture === 'true'}
      />
    </Grid>
  ));

  const buttonStyles = {
    color: ' #414141',
    marginTop: 30,
    textTransform: 'lowercase',
  };

  const bottomNavBar = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  useEffect(() => {
    if (resetCounter) {
      setCurrentPage(1);
      setReset(false);
    }
  }, [resetCounter, setReset]);

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: 10, marginLeft: isPhone ? 0 : -8 }}>
        {renderCards}
      </Grid>
      <div style={bottomNavBar}>
        <div>
          {indexOfFirstTodo > 0 ? (
            <Button onClick={() => setCurrentPage(currentPage - 1)} style={buttonStyles}>
              <ArrowBackIosIcon />
              {indexOfLastTodo >= allProducts.length ? allProducts.length : indexOfLastTodo} von {allProducts.length}
            </Button>
          ) : null}
        </div>
        <div>
          {indexOfLastTodo < allProducts.length ? (
            <Button onClick={() => setCurrentPage(currentPage + 1)} style={buttonStyles}>
              {t('next_btn')}
              <ArrowForwardIosIcon />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

FabricPagination.propTypes = {
  filter: PropTypes.func,
  resetCounter: PropTypes.bool,
  setReset: PropTypes.func,
  updateCompactList: PropTypes.func,
  numberofFabrics: PropTypes.number.isRequired,
  searchBarValue: PropTypes.string,
  isArticleSearch: PropTypes.bool,
  isCompareMode: PropTypes.bool,
  isSearchBar: PropTypes.bool,
};

FabricPagination.defaultProps = {
  filter: null,
  resetCounter: false,
  setReset: null,
  updateCompactList: null,
  searchBarValue: '',
  isArticleSearch: false,
  isCompareMode: false,
  isSearchBar: false,
};

import { Subject } from 'rxjs';

const triggerOpenDialogSubject$ = new Subject(0, false);
const triggerDetailsDialogSubject$ = new Subject();
const triggerCloseDialogSubject$ = new Subject(0);
const triggerOpenMiniViewDialogSubject$ = new Subject();
const triggerCloseMiniViewDialogSubject$ = new Subject();
const triggerCompareViewSubject$ = new Subject();
const triggerInquiryDialogSubject$ = new Subject();

export function openFabricViewDialog(index, isArticleSearch) {
  return triggerOpenDialogSubject$.next({ index, isArticleSearch });
}

export function getTriggerOpenDialog() {
  return triggerOpenDialogSubject$;
}

export function closeFabricViewDialog() {
  return triggerCloseDialogSubject$.next();
}

export function getTriggerCloseDialog() {
  return triggerCloseDialogSubject$;
}

export function openMiniView(items) {
  return triggerOpenMiniViewDialogSubject$.next(items);
}

export function getTriggerOpenMiniView() {
  return triggerOpenMiniViewDialogSubject$;
}

export function triggerCloseMiniView() {
  return triggerCloseMiniViewDialogSubject$.next();
}

export function getTriggerCloseMiniView() {
  return triggerCloseMiniViewDialogSubject$;
}

export function updateCompareView() {
  return triggerCompareViewSubject$.next();
}

export function onValueChange() {
  return triggerCompareViewSubject$;
}

export function openInquiryDialog(id) {
  return triggerInquiryDialogSubject$.next(id);
}

export function getTriggerInquiryDialog() {
  return triggerInquiryDialogSubject$;
}

export function openFabricDetailsDialog(isArticleSearch) {
  return triggerDetailsDialogSubject$.next(isArticleSearch || false);
}

export function getTriggerFabricDetailsDialog() {
  return triggerDetailsDialogSubject$;
}

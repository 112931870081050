import Container from '@material-ui/core/Container';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { Button, CircularProgress, Fab, Paper, Snackbar, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useNavigate } from 'react-router-dom';
import LoggedInNavigationBar from '../components/navigation/LoggedInNavigationBar';
import api from '../api/api';
import { useData } from '../components/DataProvider';

const FormData = require('form-data');

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  snackbarStyleViaContentProps: {
    backgroundColor: (props) => (props.responseState.isError ? 'red' : '#efc743'),
  },
}));

export default function UserProfilePage() {
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const classes = useStyles({ responseState });
  const [saveState, setSaveState] = useState(false);
  const [helperTextPasswordRepeat, setHelperTextPasswordRepeat] = useState('');
  const helperTextPassword = '';
  const { user, setUser, selectedItems, allScenes, selectedSceneIndex, highlightColor } = useData();
  const [userLogo, setUserLogo] = useState(null);
  const [userLogoURL, setUserLogoURL] = useState(null);
  const [isInvalidPassword, setIsinvalidPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let isValid = false;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { search } = useLocation();
  const userId = new URLSearchParams(search).get('loggedInUserId');
  const parentUserId = new URLSearchParams(search).get('loggedInUserId');

  const [userData, setUserData] = useState({
    emailAddress: '',
    username: '',
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
    companyName: '',
    streetName: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
    salutationContactPerson: '',
    firstNameContactPerson: '',
    lastNameContactPerson: '',
    emailAddressContactPerson: '',
    phoneNumberContactPerson: '',
    faxNumberContactPerson: '',
    website: '',
    setId: '',
    highlightColor: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const checkIsValid = (fieldName) => fieldName === '' || fieldName === undefined;

  const getCurrentSelectedItems = () => {
    const currentGroups = allScenes[selectedSceneIndex].scene.hotspots
      .map((elem) => elem.product_group)
      .filter((elem) => elem !== '7318');
    const currentSelectedItems = Object.values(selectedItems)
      .map((elem) => {
        return currentGroups.includes(elem.subgroupId) ? elem : '';
      })
      .filter((elem) => elem !== '');

    return currentSelectedItems;
  };

  const downloadPDF = async () => {
    setLoading(true);
    const currentSelectedItems = getCurrentSelectedItems();
    const pdfParams = {
      isWatchList: false,
      userId,
      fabricIds: currentSelectedItems.map((elem) => elem.fabric.id),
      setId: userData.setId,
      hs1: currentSelectedItems[1] ? currentSelectedItems[1].fabric.post_title : '308011',
      hs2: currentSelectedItems[1] ? currentSelectedItems[1].fabric.post_title : '297810',
      hs3: currentSelectedItems[2] ? currentSelectedItems[2].fabric.post_title : '287803',
      folder_name: allScenes[selectedSceneIndex].scene.sub_path,
      scene_name: allScenes[selectedSceneIndex].scene.post_title,
      product_subgroup_id_1: allScenes[selectedSceneIndex].product_subgroup.filter((elem) => elem !== '7318')[0],
      product_subgroup_id_2: allScenes[selectedSceneIndex].product_subgroup.filter((elem) => elem !== '7318')[1],
      product_subgroup_id_3: allScenes[selectedSceneIndex].product_subgroup.filter((elem) => elem !== '7318')[2],
      shadow_color: 'Blau',
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    setLoading(false);
    window.open(url);
  };

  const checkPassword = (password, passwordRepeat) => {
    const regexPasswordLength = /.{8,}/; // test for at least 8 characters
    const regexPasswordContainsUpperCase = /[A-Z]/; // test for uppercase letter
    const regexPasswordContainsLowerCase = /[a-z]/; // test for lowercase letter
    const regexPasswordContainsNumber = /\d/; // test for number
    const regexPasswordContainsSpecialChar = /\W/; // test for special character

    if (passwordRepeat === '' || passwordRepeat === undefined) {
      setHelperTextPasswordRepeat('Das Feld darf nicht leer sein.');
      return true;
    }
    if (password !== passwordRepeat) {
      setHelperTextPasswordRepeat('Die Passwörter stimmen nicht überein.');
      return true;
    }
    if (!regexPasswordLength.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must be at least 8 characters.');
      return true;
    }

    if (!regexPasswordContainsUpperCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain an uppercase character.');
      return true;
    }

    if (!regexPasswordContainsLowerCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain an lowercase character.');
      return true;
    }

    if (!regexPasswordContainsNumber.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain a number.');
      return true;
    }

    if (!regexPasswordContainsSpecialChar.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain a special character.');
      return true;
    }

    setHelperTextPasswordRepeat('');
    return false;
  };

  function handleSave(e) {
    e.preventDefault();

    const requiredData = [];
    requiredData.push(userData.companyName);
    requiredData.push(userData.streetName);
    requiredData.push(userData.houseNumber);
    requiredData.push(userData.zipCode);
    requiredData.push(userData.city);
    requiredData.push(userData.country);

    const formData = new FormData();
    formData.append('company_name', userData.companyName);
    formData.append('company_address', `${userData.streetName} ${userData.houseNumber}`);
    formData.append('company_zip', userData.zipCode);
    formData.append('company_city', userData.city);
    formData.append('company_country', userData.country);
    formData.append('company_website', '');
    formData.append('company_fax_number', userData.faxNumberContactPerson);
    formData.append('company_email_address', userData.emailAddressContactPerson);
    formData.append('company_phone_number', userData.phoneNumberContactPerson);
    formData.append('contact_person_salutation', userData.salutationContactPerson);
    formData.append('contact_person_firstname', userData.firstNameContactPerson);
    formData.append('user_id', userId);
    formData.append('company_website', userData.website);
    formData.append('contact_person_lastname', userData.lastNameContactPerson);
    formData.append('parentUserId', parentUserId);

    if (userLogo) formData.append('company_logo', userLogo);

    if (userData.newPassword !== '' && userData.newPasswordRepeat !== '') {
      const isInvalidPW = checkPassword(userData.newPassword, userData.newPasswordRepeat);
      setIsinvalidPassword(isInvalidPW);

      if (!isInvalidPW) {
        formData.append('oldPW', userData.oldPassword);
        formData.append('newPassword', userData.newPassword);
        formData.append('newPasswordRepeat', userData.newPasswordRepeat);
      }
    }

    isValid = requiredData.every((o) => o !== '');
    if (isValid) {
      const promise = api.postUserData(formData);
      promise.then((response) => {
        if (response.status === 200) {
          setResponseState({ isError: false, msg: 'Data has been saved.' });
        } else {
          setResponseState({
            isError: true,
            msg: `There has been an error occurred. Response status:
          ${response.status}`,
          });
        }
        setOpen(true);
      });
    }
    setSaveState(true);
  }

  const handleUpload = (e) => {
    setUserLogo(e.target.files[0]);
    setUserLogoURL(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('jwt');
      setLoading(true);
      const userDataBackend = await api.getUserDataWithAuth(userId, token);
      if (userDataBackend.data === 'UserId is not matching!') {
        navigate(`/?userId=${userId}`);
        return;
      }

      const userObjects = userDataBackend.data.data.userData;
      const streetName = userObjects.company_address.split(' ')[0];
      const streetNumber = userObjects.company_address.split(' ')[1];

      setUserLogoURL(
        userObjects.user_logo !== ''
          ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
              userObjects.user_logo
            }`
          : '',
      );

      setUserData({
        emailAddress: userObjects.company_email_address,
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        username: userObjects.username,
        companyName: userObjects.company_name,
        streetName,
        houseNumber: streetNumber,
        zipCode: userObjects.company_zip,
        city: userObjects.company_city,
        country: userObjects.company_country,
        salutationContactPerson: userObjects.contact_person_salutation,
        firstNameContactPerson: userObjects.contact_person_firstname,
        lastNameContactPerson: userObjects.contact_person_lastname,
        emailAddressContactPerson: userObjects.company_email_address,
        phoneNumberContactPerson: userObjects.company_phone_number,
        highlightColor: userObjects.highlightcolor,
        faxNumberContactPerson: userObjects.company_fax_number,
        website: userObjects.company_website,
        setId: userObjects.setId,
      });
      setLoading(false);
    })();
  }, [navigate, setUser, user, userId]);

  return (
    <Container>
      <form onSubmit={handleSave}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LoggedInNavigationBar />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2">{t('logo_info_text')}</Typography>
          </Grid>

          <Grid
            container
            item
            xs={8}
            style={{
              overflowY: 'scroll',
              maxHeight: 700,
            }}
          >
            {loading && <CircularProgress style={{ display: 'flex', margin: '0 auto', color: highlightColor }} />}
            <Grid item xs={12}>
              <h4>Logindaten</h4>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="emailAddress"
                name="emailAddress"
                label="E-Mail Adresse*"
                variant="outlined"
                className={classes.textField}
                size="small"
                value={userData.username}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="oldPassword"
                style={{ width: '100%', marginLeft: 0, marginRight: 0 }}
                label="Altes Passwort"
                name="oldPassword"
                variant="outlined"
                type="password"
                size="small"
                className={classes.textField}
                value={userData.oldPassword}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="newPassword"
                style={{ width: '100%', paddingRight: 10, marginLeft: 0, marginRight: 0 }}
                label="Neues Passwort"
                name="newPassword"
                variant="outlined"
                type="password"
                size="small"
                error={isInvalidPassword}
                helperText={helperTextPassword}
                className={classes.textField}
                value={userData.newPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="newPasswordRepeat"
                style={{ width: '100%', marginLeft: 0, marginRight: 0 }}
                label="Neues Passwort wiederholen"
                name="newPasswordRepeat"
                variant="outlined"
                type="password"
                size="small"
                error={isInvalidPassword}
                helperText={helperTextPasswordRepeat}
                className={classes.textField}
                value={userData.newPasswordRepeat}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <h4>{t('company_data')}</h4>
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="companyName"
                name="companyName"
                label={`${t('company_name')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.companyName) : ''}
                helperText={saveState && checkIsValid(userData.companyName) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.companyName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                style={{ width: '100%', paddingRight: 10 }}
                id="streetName"
                name="streetName"
                label={`${t('company_address')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.streetName) : ''}
                helperText={saveState && checkIsValid(userData.streetName) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.streetName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="houseNumber"
                name="houseNumber"
                label={`${t('company_number')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.houseNumber) : ''}
                helperText={saveState && checkIsValid(userData.houseNumber) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.houseNumber}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <TextField
                style={{ width: '100%', paddingRight: 10, marginLeft: 0 }}
                id="zipCode"
                name="zipCode"
                label={`${t('company_zip')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.zipCode) : ''}
                helperText={saveState && checkIsValid(userData.zipCode) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.zipCode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="city"
                name="city"
                label={`${t('company_location')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.city) : ''}
                helperText={saveState && checkIsValid(userData.city) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="country"
                name="country"
                label={`${t('company_country')}* `}
                variant="outlined"
                className={classes.textField}
                size="small"
                error={saveState ? checkIsValid(userData.country) : ''}
                helperText={saveState && checkIsValid(userData.country) ? 'Das Feld darf nicht leer sein.' : ''}
                value={userData.country}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <h4>{t('contact_person')}</h4>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '45%', marginLeft: 0 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-outlined-label">{t('salutation')}</InputLabel>
                <Select
                  id="salutationContactPerson"
                  name="salutationContactPerson"
                  labelId="demo-simple-select-outlined-label"
                  label={`${t('salutation')}* `}
                  value={userData.salutationContactPerson}
                  onChange={handleChange}
                >
                  <MenuItem value="Herr">Herr</MenuItem>
                  <MenuItem value="Frau">Frau</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="firstNameContactPerson"
                style={{ width: '100%', paddingRight: 10, marginLeft: 0, marginRight: 0 }}
                label={t('firstname')}
                name="firstNameContactPerson"
                variant="outlined"
                size="small"
                value={userData.firstNameContactPerson}
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="lastNameContactPerson"
                style={{ width: '100%', marginLeft: 0, marginRight: 0 }}
                label={t('lastname')}
                name="lastNameContactPerson"
                variant="outlined"
                size="small"
                className={classes.textField}
                value={userData.lastNameContactPerson}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="emailAddressContactPerson"
                name="emailAddressContactPerson"
                label={t('email')}
                variant="outlined"
                className={classes.textField}
                size="small"
                value={userData.emailAddressContactPerson}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="phoneNumberContactPerson"
                name="phoneNumberContactPerson"
                label={t('telephone_number')}
                variant="outlined"
                className={classes.textField}
                size="small"
                value={userData.phoneNumberContactPerson}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="website"
                name="website"
                label={t('Website')}
                variant="outlined"
                className={classes.textField}
                size="small"
                value={userData.website}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', marginLeft: 0 }}
                id="faxNumberContactPerson"
                name="faxNumberContactPerson"
                label={t('fax_number')}
                variant="outlined"
                className={classes.textField}
                size="small"
                value={userData.faxNumberContactPerson}
                onChange={handleChange}
              />
              <br />
              <br />
            </Grid>
          </Grid>

          <Grid container item xs={2} alignItems="flex-end" alignContent="center" justify="space-evenly">
            <Grid item xs={12} stlye={{ border: '1px solid black' }}>
              <Typography variant="body2">Logo</Typography>
              <br />
              <Paper elevation={1} style={{ width: 250 }}>
                {userLogoURL && <img alt="profilePicture" width="100%" className={classes.media} src={userLogoURL} />}
              </Paper>
              <br />
              <label htmlFor="contained-button-file">
                <Fab component="span" className={classes.button}>
                  <AddPhotoAlternateIcon />
                </Fab>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUpload}
                />
              </label>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                style={{
                  marginTop: '4%',
                  marginBottom: '4%',
                  float: 'right',
                  fontSize: '20px',
                }}
              >
                {t('save')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" style={{ float: 'right', marginTop: '4%', marginBottom: '4%' }}>
                {t('preview_message_fabric')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{
                  marginTop: '4%',
                  marginBottom: '4%',
                  float: 'right',
                  fontSize: '20px',
                }}
                disabled={loading}
                onClick={downloadPDF}
              >
                {t('pdf_preview')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
      />
    </Container>
  );
}

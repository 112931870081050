import Container from '@material-ui/core/Container';
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CopyToClipboard from '@vigosan/react-copy-to-clipboard';
import {
  Button,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CardActions from '@material-ui/core/CardActions';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Iframe from 'react-iframe';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import QRCode from 'qrcode.react';
import { Trans, useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import FormData from 'form-data';
import { useLocation, useNavigate } from 'react-router-dom';
import colorIcon from '../assets/colorIcon/colorIcon.png';
import LoggedInNavigationBar from '../components/navigation/LoggedInNavigationBar';
import ClipboardContent from '../components/ClipboardContent';
import { useData } from '../components/DataProvider';
import { getProductGroupById } from '../util/util';
import api from '../api/api';
import MuiCardSceneSelection from '../components/EmbedOptions/MuiCardSceneSelection';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    fontSize: '0.8em',
  },
  expand: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  buttonStyles: {
    fontSize: '20px',
    textTransform: 'none',
    marginRight: '2%',
    marginLeft: '2%',
  },
  typographyStyles: {
    color: '#414141',
    fontSize: '20px',
    marginBottom: '1%',
  },
  coverStyles: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popoverStyles: {
    position: 'absolute',
    zIndex: '2',
  },
  snackbarStyleViaContentProps: {
    backgroundColor: (props) => (props.responseState.isError ? 'red' : props.defaultColor),
  },
}));

export default function EmbedOptionsPage() {
  const theme = useTheme();
  const defaultColor = theme.palette.select.color;
  const { jsonData, selectColor } = useData();
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const classes = useStyles({ responseState, defaultColor });
  const [openDialog, setOpenDialog] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [lang, setLang] = useState('de');
  const [possibleProductTypes, setPossibleProductTypes] = useState([]);
  const [iframeWidth, setIframeWidth] = useState('1920');
  const [isCustomizedSelected, setIsCustomizedSelected] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const { t } = useTranslation();
  const [enableApplicationEmail, setEnableApplicationEmail] = useState(false);
  const [applicationContactEmail, setApplicationContactEmail] = useState('');
  const [showEmbedBanner, setShowEmbedBanner] = useState(false);
  const [showHighlightColor, setShowHighlightColor] = useState(false);
  const [whitelabelAppText, setWhiteLabelAppText] = useState('');
  const [highlightColor, setHighlightColor] = useState(theme.palette.select.color);
  const [isExpanded, setIsExpanded] = useState(false);
  const [whiteLabelLogo, setWhiteLabelLogo] = useState(null);
  const [userLogoURL, setUserLogoURL] = useState(null);
  const states = {};
  const numberOfProductGroups = Object.keys(jsonData.product_groups).length;
  const [selectedScenes, setSelectedScenes] = useState({});
  const useAlwaysDefaultColor = true;
  const [isLoaded, setIsLoaded] = useState(false);
  const [tempUserData, setTempUserData] = useState({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  for (let i = 0; i < numberOfProductGroups; i += 1) {
    states[`checked${i}`] = true;
  }
  const [checkState, setCheckState] = useState(states);

  const { search } = useLocation();
  const userId = new URLSearchParams(search).get('loggedInUserId');
  const parentUserId = new URLSearchParams(search).get('loggedInUserId');

  const iframeHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  const hs1 = 8210;
  const hs2 = 8231;
  const navigate = useNavigate();

  const sceneIndex = 0;
  const color = highlightColor.substring(1);
  const iframeSrc = `${iframeHost}?userId=${userId}&hs1=${hs1}&hs2=${hs2}&scene=${sceneIndex}&color=${color}`;

  function initProductGroups() {
    const productGroupIds = jsonData.product_groups.map((p) => p.id);

    return productGroupIds
      .map((sceneId) => {
        return jsonData.scenes
          .filter((scene) => +scene.product_group === sceneId)
          .filter((v, i, a) => a.findIndex((scene) => scene.scene.id === v.scene.id) === i);
      })
      .filter((container) => container.length !== 0);
  }

  const productGroups = initProductGroups();

  const embedCode = `
      <script>
      if (typeof com == "undefined") var com = new Object();
      if (typeof com.sattler_ag == "undefined") com.sattler_ag = new Object();
      com.sattler_ag.mf_config = {lang: '${lang}', user_id: '${userId}', breite: '${iframeWidth}px', hoehe:  'medium'};
      </script>
      <div id="sattler_mf_iframe_container"><!-- --></div>
      <script src="https://sattler-backend-dev.datengarage.com/wp-content/themes/sattler-design-selector/js/sattler-iframe.js">
      </script>
`;

  const { vertical, horizontal, open } = snackBarState;
  const handleClick = (newSnackBarState) => () => {
    setSnackBarState({ open: true, ...newSnackBarState });
  };
  const handleClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
    setOpenDialog(false);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleUpload = (e) => {
    setWhiteLabelLogo(e.target.files[0]);
    setUserLogoURL(URL.createObjectURL(e.target.files[0]));
  };

  const saveSettings = async () => {
    const sceneIds = Object.entries(selectedScenes)
      .map((el) => el[1].filter((el2) => el2.isChecked === true))
      .flat()
      .flatMap((el) => el.id)
      .join();

    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('application_enable_contact_email', enableApplicationEmail);
    formData.append('application_contact_email', applicationContactEmail);
    formData.append('highlightcolor', highlightColor);
    formData.append('show_embedding_banner', showEmbedBanner);
    formData.append('whitelabelapptext', whitelabelAppText);
    formData.append('white_label_logo', whiteLabelLogo);
    formData.append('customer_scenes', sceneIds);
    formData.append('custom_application_width_type', iframeWidth);
    formData.append('parentUserId', parentUserId);

    const response = await api.postUserData(formData);
    if (response.status === 200) {
      setShowToast(true);
      setResponseState({ isError: false, msg: 'Data has been stored.' });
    } else {
      setResponseState({
        isError: true,
        msg: `There has been an error occurred. Response status:
          ${response.status}`,
      });
    }
  };

  function handleEmbedCompleteSelector() {}

  function handleEmbedPicture() {}

  const handleChangeIFrameWidth = (event) => setIframeWidth(event.target.value);

  const handleExpandClick = () => {
    const invertExpanded = !isExpanded;
    setShowHighlightColor(invertExpanded);
    setIsExpanded(invertExpanded);
    if (!invertExpanded) {
      setHighlightColor(defaultColor);
    }
  };

  const openColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const handleColorPickerClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (col) => {
    setHighlightColor(col.hex);
  };

  const handleChange = (event, index) => {
    const name = `checked${index}`;
    setCheckState({ ...checkState, [name]: event.target.checked });
    selectedScenes[index].forEach((el) => (el.isChecked = event.target.checked));
    setSelectedScenes({ ...selectedScenes });
  };

  function updateSceneSelection(obj, productGroupIndex) {
    const index = selectedScenes[productGroupIndex].findIndex((el) => el.id === obj.sceneId);
    selectedScenes[productGroupIndex][index] = {
      id: obj.sceneId,
      isChecked: obj.isSelected,
    };
    const newSelectedScenes = { ...selectedScenes };

    const allFalse = newSelectedScenes[productGroupIndex].every((el) => el.isChecked === false);
    const allTrue = newSelectedScenes[productGroupIndex].every((el) => el.isChecked === true);

    if (allFalse) {
      const name = `checked${productGroupIndex}`;
      setCheckState({ ...checkState, [name]: false });
    } else if (allTrue) {
      const name = `checked${productGroupIndex}`;
      setCheckState({ ...checkState, [name]: true });
    }
    setSelectedScenes(newSelectedScenes);
  }

  function isSelected(sceneObject, groupIndex) {
    if (Object.entries(selectedScenes).length > 0 && Object.entries(selectedScenes[groupIndex]).length > 0) {
      const found = selectedScenes[groupIndex].find((el) => el.id === sceneObject.scene.id);
      if (found) return found.isChecked;
      return false;
    }
    return true;
  }

  function ProductItems(productGroup, productGroupIndex) {
    const getPostTitle = () => {
      const found = jsonData.product_groups.find((el) => el.id === +productGroup[0].product_group);
      if (found) return found.post_title;
      return null;
    };

    return (
      <Card
        className={classes.root}
        variant="outlined"
        style={{
          border: '1px solid #F4F2F3',
          width: '97.5%',
          marginBottom: 15,
        }}
        key={`productItem-${productGroupIndex}`}
      >
        <div>{getPostTitle()}</div>
        <Grid container>
          {productGroup.map((el, index) => (
            <Grid item key={`cardItem-${index}`}>
              <MuiCardSceneSelection
                item={el}
                selectedValue={isSelected(el, productGroupIndex)}
                updateSceneSelection={updateSceneSelection}
                borderColor={useAlwaysDefaultColor ? defaultColor : highlightColor}
                productGroupIndex={productGroupIndex}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
    );
  }
  const uniqueProductTypes = useMemo(() => [...new Set(jsonData.scenes.map((scene) => scene.product_group))].sort(), [
    jsonData.scenes,
  ]);

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        setPossibleProductTypes(uniqueProductTypes);

        const token = localStorage.getItem('jwt');
        const userData = await api.getUserDataWithAuth(userId, token);
        if (userData.data === 'UserId is not matching!') {
          navigate(`/?userId=${userId}`);
          return;
        }

        const data = userData.data.data.userData;

        setTempUserData({ ...data });

        setUserLogoURL(
          data.WL_logo !== ''
            ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/whitelabel_logo/${
                data.WL_logo
              }`
            : '',
        );
        setWhiteLabelAppText(data.whitelabelapptext);
        setIframeWidth(data.custom_application_width_type);
        setEnableApplicationEmail(data.application_enable_contact_email);
        setShowEmbedBanner(data.show_embedding_banner === 'true');
        setEnableApplicationEmail(data.application_enable_contact_email === 'true');
        setApplicationContactEmail(data.application_contact_email);
        setHighlightColor(data.highlightcolor !== 'undefined' ? data.highlightcolor : selectColor);
        if (data.highlightcolor !== 'undefined') {
          setShowHighlightColor(data.highlightcolor.toLowerCase() !== '#efc743');
          setIsExpanded(data.highlightcolor.toLowerCase() !== '#efc743');
        }

        let initSceneStates;
        if (data.customer_scenes !== 'undefined' && data.customer_scenes !== '') {
          initSceneStates = productGroups
            .filter((el) => el.length > 0)
            .map((el) =>
              el.map((ele) => ({ id: ele.scene.id, isChecked: data.customer_scenes.includes(ele.scene.id) })),
            );
        } else {
          initSceneStates = productGroups
            .filter((el) => el.length > 0)
            .map((el) => el.map((ele) => ({ id: ele.scene.id, isChecked: true })));
        }
        setSelectedScenes(initSceneStates);
        setIsLoaded(true);
      }
    })();
  }, [isLoaded, jsonData, navigate, productGroups, selectColor, uniqueProductTypes, userId]);

  return (
    <Container>
      {isLoaded && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LoggedInNavigationBar />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" className={classes.typographyStyles}>
              {t('embed_code')}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '1%' }}>
              Kopieren Sie den folgenden HTML-Code, um den <b>Designselector</b> in Ihre Website einzubetten:
            </Typography>
            <CopyToClipboard
              render={({ copy }) => (
                <ClipboardContent
                  copy={copy}
                  handleClick={handleClick}
                  embedCode={embedCode}
                  borderColor={useAlwaysDefaultColor ? defaultColor : highlightColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} align="center">
            {tempUserData &&
              (!tempUserData.company_name ||
                !tempUserData.company_city ||
                !tempUserData.company_zip ||
                !tempUserData.user_logo) && (
                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{
                    border: '1px solid #F4F2F3',
                    width: '88%',
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" style={{ textAlign: 'left' }}>
                      <b>Bitte vervollständigen Sie Ihre Unternehmensdaten </b> <br />
                      Um das Logo und die Kontaktdaten Ihres Unternehmens im Design Selector ausgegeben zu können,
                      müssen Sie diese im Bereich <Link href={`/profile?userId=${userId}`}>Profildaten</Link>{' '}
                      vervollständigen.
                    </Typography>
                  </CardContent>
                </Card>
              )}
          </Grid>

          <Grid
            container
            item
            xs={7}
            style={{
              overflowY: 'scroll',
              maxHeight: 630,
            }}
          >
            <form onSubmit={handleEmbedCompleteSelector}>
              <Grid item xs={12}>
                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{
                    border: '1px solid #F4F2F3',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      style={{
                        color: '#414141',
                        fontSize: '20px',
                        marginBottom: '2%',
                      }}
                    >
                      Design Selector bei Ihrer Website einbetten
                    </Typography>

                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#414141',
                          marginTop: '4%',
                          marginBottom: '4%',
                        }}
                      >
                        <Typography variant="body2">
                          <b>{t('fabric_width')}</b>
                        </Typography>
                      </FormLabel>

                      <RadioGroup
                        aria-label="width"
                        name="width"
                        value={iframeWidth}
                        onChange={handleChangeIFrameWidth}
                      >
                        <FormControlLabel
                          value="1920"
                          control={<Radio />}
                          label="Sehr Groß (1920px)"
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="920"
                          control={<Radio />}
                          label="Groß (920px)"
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="720"
                          control={<Radio />}
                          label="Mittel (720px)"
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="640"
                          control={<Radio />}
                          label="Schmal (640px)"
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="1000"
                          control={<Radio />}
                          checked={isCustomizedSelected}
                          onChange={() => setIsCustomizedSelected(true)}
                          label={
                            <div>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                Benutzerdefiniert
                              </Typography>
                              <TextField
                                id="standard-basic"
                                label="Breite "
                                value={iframeWidth}
                                onChange={handleChangeIFrameWidth}
                                disabled={!isCustomizedSelected}
                                InputProps={{ inputProps: { min: 0, max: 2560 }, endAdornment: 'px' }}
                              />
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      color="textSecondary"
                      style={{
                        color: '#414141',
                        fontSize: '14px',
                        marginBottom: '2%',
                      }}
                    >
                      Sprache
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lang}
                      onChange={(e) => setLang(e.target.value)}
                    >
                      <MenuItem value="de">Deutsch</MenuItem>
                      <MenuItem value="en">Englisch</MenuItem>
                      <MenuItem value="fr">Französisch</MenuItem>
                    </Select>

                    <FormGroup row style={{ marginTop: '4%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="userRequest"
                            checked={enableApplicationEmail}
                            onChange={() => setEnableApplicationEmail(!enableApplicationEmail)}
                          />
                        }
                        label="User können Anfragen senden"
                        style={{ width: '100%' }}
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: isExpanded,
                        })}
                        aria-expanded={isExpanded}
                        aria-label="show more"
                      />
                      <Collapse in={enableApplicationEmail} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                        <CardContent style={{ padding: '0px' }}>
                          <TextField
                            style={{ width: '90%', marginLeft: '5%' }}
                            id="emailAddressUserRequest"
                            name="emailAddressUserRequest"
                            label={t('email')}
                            variant="outlined"
                            className={classes.textField}
                            size="small"
                            value={applicationContactEmail}
                            onChange={(event) => setApplicationContactEmail(event.target.value)}
                          />
                        </CardContent>
                      </Collapse>

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="showEmbedBanner"
                            checked={showEmbedBanner}
                            onChange={() => setShowEmbedBanner(!showEmbedBanner)}
                          />
                        }
                        label="Einbettungsbanner anzeigen"
                        style={{ width: '100%' }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="individualHighlightcolor"
                            checked={showHighlightColor}
                            onChange={() => {
                              setShowHighlightColor(!showHighlightColor);
                              setIsExpanded(handleExpandClick);
                            }}
                          />
                        }
                        label="individuelle Highlightfarbe definieren"
                        style={{ width: '100%' }}
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: isExpanded,
                        })}
                        aria-expanded={isExpanded}
                        aria-label="color"
                      />
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                        <CardActions style={{ padding: '0px' }}>
                          <Button
                            onClick={openColorPicker}
                            style={{
                              width: '50%',
                              marginLeft: '5%',
                              border: '1px solid rgb(244, 242, 243)',
                            }}
                          >
                            <img src={colorIcon} style={{ width: '12%', marginRight: '5%' }} alt="color Icon" />

                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                              Highlightfarbe wählen
                            </Typography>
                          </Button>
                          {displayColorPicker ? (
                            <div className={classes.popoverStyles}>
                              <div
                                className={classes.coverStyles}
                                onClick={handleColorPickerClose}
                                role="presentation"
                              />
                              <ChromePicker color={highlightColor} onChange={handleColorChange} disableAlpha />
                            </div>
                          ) : null}
                        </CardActions>
                      </Collapse>
                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                        {highlightColor.hex}
                      </Typography>
                    </FormGroup>
                  </CardContent>

                  <Card
                    className={classes.root}
                    variant="outlined"
                    style={{
                      border: '1px solid #F4F2F3',
                      width: '97.5%',
                    }}
                  >
                    <CardContent>
                      <Typography variant="body2">
                        <b>Anlagentypen</b>
                      </Typography>

                      <Card
                        className={classes.root}
                        variant="outlined"
                        style={{
                          border: '1px solid #F4F2F3',
                          width: '97.5%',
                        }}
                      >
                        <CardContent>
                          {possibleProductTypes.length > 0 &&
                            possibleProductTypes.map((type, index) => (
                              <FormControlLabel
                                key={type}
                                control={<Checkbox name={type} />}
                                label={getProductGroupById(type, jsonData).post_title}
                                style={{ width: '100%' }}
                                className={clsx(classes.expand, {
                                  [classes.expandOpen]: isExpanded,
                                })}
                                aria-expanded={isExpanded}
                                aria-label="color"
                                checked={checkState[`checked${index}`]}
                                onChange={(event) => handleChange(event, index)}
                              />
                            ))}
                        </CardContent>
                      </Card>
                    </CardContent>
                    <Divider />
                    <Grid container>{productGroups.map(ProductItems)}</Grid>
                    <Divider />
                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: '14px' }}>
                        Bei Fragen zur Einbettung steht Ihnen das Design Selector - Support Team zur Verfügung. <br />
                        Senden Sie uns eine aussagekräftige Beschreibung Ihres Anliegens an folgende E-Mail Adresse:
                        <br />
                        <a href="mailto:abc@example.com" style={{ color: '#414141' }}>
                          design-selector@sattler-global.com{' '}
                        </a>
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          fontWeight: 700,
                          marginTop: '1%',
                          marginBottom: '1%',
                        }}
                      >
                        {t('collect_text')}
                        <div style={{ fontWeight: 500 }}>
                          <Trans
                            i18nKey="info_collect_text"
                            t={t}
                            components={[
                              <a href={`${process.env.REACT_APP_BASE_URL}datenschuzerklaerung`}>
                                Datenschutzerklärung
                              </a>,
                            ]}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Card>
                  <CardActions style={{ float: 'right', width: '55%' }}>
                    <Button className={classes.buttonStyles} onClick={handleClickOpen}>
                      Vorschau
                    </Button>
                    <Button onClick={saveSettings} className={classes.buttonStyles}>
                      Einstellungen speichern
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </form>
          </Grid>

          <Grid container item xs={5} align="flex-start">
            <form onSubmit={handleEmbedPicture}>
              <Card
                className={classes.root}
                variant="outlined"
                style={{
                  width: '100%',
                  fontWeight: 700,
                  marginTop: '1%',
                  marginBottom: '1%',
                }}
              >
                <CardContent>
                  <Typography
                    color="textSecondary"
                    style={{
                      color: '#414141',
                      fontSize: '20px',
                      marginBottom: '1%',
                      textAlign: 'left',
                    }}
                  >
                    White Label App Content
                  </Typography>

                  <QRCode
                    style={{ margin: '0 auto', display: 'flex' }}
                    value={`https://sattler-whitelabel.datengarage.com/?userId=${userId}`}
                  />
                  <br />
                  <Link
                    target="_blank"
                    rel="noopener"
                    href={`https://sattler-whitelabel.datengarage.com/?userId=${userId}`}
                  >
                    Link zu deiner Whitelabel App
                  </Link>
                  <br />
                  <TextField
                    id="whitelabelAppText"
                    name="whiteLabelAppText"
                    label="Whitelabel App Text"
                    variant="outlined"
                    className={classes.textField}
                    size="small"
                    value={whitelabelAppText}
                    onChange={(event) => setWhiteLabelAppText(event.target.value)}
                  />
                  <Grid container item xs={2} alignItems="center" alignContent="center" justify="center">
                    <Grid item xs={12} stlye={{ border: '1px solid black' }}>
                      <Typography variant="body2">Logo</Typography>
                      <br />
                      <Paper elevation={1} style={{ width: 200 }}>
                        {userLogoURL && (
                          <img alt="profilePicture" width="100%" className={classes.media} src={userLogoURL} />
                        )}
                      </Paper>
                      <br />
                      <label htmlFor="contained-button-file">
                        <Fab component="span" className={classes.button}>
                          <AddPhotoAlternateIcon />
                        </Fab>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleUpload}
                        />
                      </label>
                    </Grid>
                  </Grid>
                  <CardActions style={{ float: 'right', width: '55%' }}>
                    <Button onClick={saveSettings} className={classes.buttonStyles}>
                      {t('save')}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </form>
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Code wurde erfolgreich kopiert"
        key={vertical + horizontal}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showToast}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
      />

      <Dialog open={openDialog} fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="iframe-dialog">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Einbettungsvorschau
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: '0 auto' }}>
            <Iframe url={iframeSrc} width={iframeWidth} height="800" display="initial" position="relative" />
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

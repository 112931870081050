import { createMuiTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core';
import OpenSans from './fonts/OpenSans-Regular.ttf';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans',
    fontDisplay: 'swap',
    fontStyle: 'normal',
    src: `
    local('OpenSans'),
    local('OpenSans-Regular'),
    url(${OpenSans}) format('ttf')
  `,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1730a4',
      box: '#F8F8F8',
    },
    background: {
      default: '#fff',
    },
    secondary: {
      main: '#78BACC',
    },
    text: {
      primary: '#414141',
    },
    select: {
      color: '#EFC743',
    },
    wall: {
      color: '#eee3e3',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});

const theme = responsiveFontSizes(muiTheme);
export default theme;

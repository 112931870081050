import React, { useEffect, useState } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigationBar from '../components/navigation/NavigationBar';
import Video from '../assets/video/DS_Loading_Loop_V2.mp4';

const unityContext = new UnityContext({
  loaderUrl: 'Build/sattler_schattensimulator.loader.js',
  dataUrl: 'Build/sattler_schattensimulator.data',
  frameworkUrl: 'Build/sattler_schattensimulator.framework.js',
  codeUrl: 'Build/sattler_schattensimulator.wasm',
});

const ShadowSimulator = () => {
  const [loading, setLoading] = useState(0);

  unityContext.on('progress', (progression) => {
    setLoading(progression);
  });

  function handleOnClickFullscreen() {
    unityContext.setFullscreen(true);
  }

  useEffect(() => {
    unityContext.on('UnityNotification', (message) => {
      console.log(message);
      unityContext.send('ScriptObject', 'UpdateLocalization', 'de');
    });
  });

  return (
    <Container disableGutters maxWidth="lg">
      <NavigationBar />
      <Unity unityContext={unityContext} className="unity-canvas" />
      {loading !== 1 && (
        <Box width="1230" mr={1}>
          <Box top="5%" left={0} bottom={0} right={0} position="absolute" display="flex" justifyContent="center">
            {/* Typography variant="caption" component="div" color="textSecondary">
                    {`${Math.round(loading * 100)}%`}
            </Typography> */}
            <div>
              <video
                style={{ height: 600, border: '3px solid #f3f3f3' }}
                loop
                autostart
                autoPlay
                src={Video}
                type="video/mp4"
              />
            </div>
          </Box>
        </Box>
      )}
      {loading === 1 && <Button onClick={() => handleOnClickFullscreen()}>Vollbild-Modus</Button>}
    </Container>
  );
};
export default ShadowSimulator;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useData } from '../DataProvider';
import { getPictogramSrcByProductSubgroupId, getProductSubgroupById } from '../../util/util';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '25vw',
    width: '100%',
    minHeight: 0,
    'justify-content': (props) => (props.isPhone ? 'center' : 'flex-end'),
    marginTop: (props) => (props.isPhone ? 15 : -7),
  },
  content: {
    zIndex: 999,
    background: 'white',
    width: 115,
    height: 40,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
    border: '1px solid transparent',
  },
  iPhoneStyles: {
    justifyContent: 'center',
  },
  normalStyles: {
    justifyContent: 'flex-end',
  },
}));

const getImgSrc = (selectedItemsPerIndex, index) => {
  if (selectedItemsPerIndex[index]) {
    return `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_FOLDER}${selectedItemsPerIndex[index].fabric.post_title}.jpg`;
  }
  return null;
  /* return `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_FOLDER}${
    getSelectedItemById(selectedItems, productGroup).fabric.post_title
  }.jpg`; */
};

export default function PreviewButtons({
  showMiniView,
  selectedItems: selectedItemsPerIndex,
  styles,
  position,
  items,
}) {
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isPortraitModeOnPhone = isPhone && isPortrait;
  const classes = useStyles({ isPhone, isPortraitModeOnPhone });
  const { jsonData, selectedSceneIndex, allScenes, selectColor } = useData();
  const [selectedButton, setSelectedButton] = useState(-1);

  const images = allScenes[selectedSceneIndex].scene.hotspots
    ? allScenes[selectedSceneIndex].scene.hotspots
        .map((hotspot) => {
          if (hotspot.product_group !== '7318') {
            return {
              url: '',
              title: getProductSubgroupById(hotspot.product_group, jsonData)?.subline_2,
              width: `${100 / allScenes.length}%`,
              productGroup: hotspot.product_group,
            };
          }
          return null;
        })
        .filter((x) => x)
    : null;

  const handleClick = (image, index) => {
    if (showMiniView) {
      showMiniView(index, image.productGroup);
    }
    setSelectedButton(index);
  };

  return (
    <div
      style={{
        ...(position === 'right' ? { justifyContent: 'flex-end' } : { justifyContent: 'flex-start' }),
        ...styles,
      }}
      className={classes.root}
    >
      {images &&
        images.map((image, index) => (
          <div
            key={`previewButtons${image.productGroup}`}
            className={classes.content}
            style={selectedButton === index ? { border: `1px solid ${selectColor}` } : null}
            onClick={() => handleClick(image, index)}
            role="presentation"
          >
            <div>
              <img
                src={getPictogramSrcByProductSubgroupId(image.productGroup, jsonData)}
                alt={`Piktrogramm ${image.title}`}
                style={{ position: 'absolute', right: 80, top: 5, width: 30 }}
              />
            </div>
            <div>
              <img
                src={getImgSrc(selectedItemsPerIndex, index, image.productGroup, items)}
                alt={image.title}
                style={{ width: 77, height: '100%', padding: 2 }}
              />
            </div>
          </div>
        ))}
    </div>
  );
}

PreviewButtons.propTypes = {
  showMiniView: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Object).isRequired,
  position: PropTypes.string,
};

PreviewButtons.defaultProps = {
  showMiniView: null,
  position: 'right',
};

import * as React from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useData } from '../DataProvider';
import fullScreenIconOpen from '../../assets/others/fullscreen-24px.svg';
import fullScreenIconClose from '../../assets/others/fullscreen_exit-24px.svg';
import { isPageInIFrame } from '../../util/util';

export default function FullScreenButton() {
  const [isHovering, setHovering] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState('transparent');
  const { isFullScreen, setFullScreen } = useData();
  const fullScreenText = 'Vollbildmodus schließen';
  const halfScreenText = 'Vollbildmodus öffnen';

  const useStyles = makeStyles(() => ({
    buttonLabelStyle: {
      position: 'absolute',
      right: '1%',
      top: '1%',
      background: backgroundColor,
      width: '200px',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'left',
      paddingLeft: '8px',
    },
    buttonLabelText: {
      textAlign: 'center',
      lineHeight: '40px',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (isHovering) {
      setBackgroundColor('#FFFFFF 0% 0% no-repeat padding-box;');
    } else {
      setBackgroundColor('transparent');
    }
  }, [isHovering]);

  function showButtonLabel() {
    return <div>{isFullScreen ? fullScreenText : halfScreenText}</div>;
  }

  const handleClick = () => {
    localStorage.setItem('fullscreen', !isFullScreen);
    setFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (isPageInIFrame()) {
      window.parent.postMessage(isFullScreen ? 'enterFullscreen' : 'exitFullscreen', '*');
    }

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        if (isFullScreen) setFullScreen(!isFullScreen);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isFullScreen, setFullScreen]);

  return (
    <div
      className={clsx({
        [classes.buttonLabelStyle]: true,
        [classes.buttonLabelText]: !isFullScreen,
        'hotspot ': true,
      })}
      onMouseLeave={() => setHovering(false)}
    >
      <div>{isHovering ? showButtonLabel() : null}</div>
      <div className="button-wrap">
        <button
          type="button"
          className="pulse-button"
          style={{ background: 'unset' }}
          onClick={handleClick}
          onMouseEnter={() => setHovering(true)}
        >
          <img src={isFullScreen ? fullScreenIconClose : fullScreenIconOpen} alt="fullscreen icon" />
        </button>
      </div>
    </div>
  );
}

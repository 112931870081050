import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from 'react-responsive';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormData from 'form-data';
import { Snackbar, CircularProgress } from '@material-ui/core';
import CustomerInquiryForm from './CustomerInquiryForm';
import { getTriggerInquiryDialog } from '../Subjects/subjects';
import api from '../../api/api';
import { useData } from '../DataProvider';

export default function CustomerInquiryDialog({ margin }) {
  const [open, setOpen] = useState(false);
  const [cardId, setCardId] = useState(-1);
  const { watchMap, userId } = useData();
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showLoading, setShowloading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const maxWidth = isTabletOrMobile ? 'sm' : 'md'; // sm, md, lg
  const childRef = useRef();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setCardId(-1);
    setOpen(true);
  };

  const sendCustomerRequest = async (formData) => {
    setShowloading(true);

    const data = new FormData();
    data.append('email', formData[0].value);
    data.append('firstName', formData[1].value);
    data.append('lastName', formData[2].value);
    data.append('regarding', formData[3].value);
    data.append('note', formData[4].value);
    data.append('userId', userId);
    setOpen(false);
    const fabrics = [];
    watchMap.forEach((value) => {
      fabrics.push(value.product.post_title);
    });
    data.append('fabrics', fabrics.join(', '));
    const response = await api.sendCustomerRequest(data);
    if (response.status === 200) {
      setResponseState({ isError: false, msg: 'Email has been sent.' });
      setOpenSnackbar(true);
      setOpen(false);
      setShowloading(false);
    } else {
      setResponseState({
        isError: true,
        msg: `There has been an error occurred. Response status:
      ${response.status}`,
      });
      setShowloading(false);
    }
  };

  const handleClose = () => {
    childRef.current.submit();
  };

  getTriggerInquiryDialog().subscribe((id) => {
    setCardId(id);
    setOpen(true);
  });

  return (
    <>
      <Button
        style={{
          textTransform: 'capitalize',
          marginLeft: margin === 'true' ? 40 : 0,
          marginTop: margin === 'true' ? 25 : 0,
        }}
        onClick={handleClickOpen}
      >
        {t('request')}
      </Button>
      <Dialog maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{t('request')}</DialogTitle>
        <DialogContent>
          <CustomerInquiryForm onSubmit={(formData) => sendCustomerRequest(formData)} ref={childRef} cardId={cardId} />
        </DialogContent>
        {showLoading && <CircularProgress style={{ margin: '0 auto', display: 'flex' }} />}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Abbrechen</Button>
          <Button onClick={handleClose}>{t('send_request')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      />
    </>
  );
}

import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomerInquiryDialog from '../FabricDialog/CustomerInquiryDialog';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useData } from '../DataProvider';
import FabricItemInfoTab from './FabricItemInfoTab';
import api from '../../api/api';

export default function WatchListDialog({ handleClose }) {
  const maxWidth = 'md';
  const { watchMap, deleteAllPaginationCards, userId, setId } = useData();
  const { t } = useTranslation();

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  const downloadPDF = async () => {
    const pdfParams = {
      isWatchList: true,
      userId,
      fabricIds: Array.from(watchMap).map(([, value]) => value.product.id),
      setId,
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    window.open(url);
  };

  function AddCardTypesItems() {
    return Array.from(watchMap).map(([key, value]) => (
      <Grid style={{ flexGrow: 1 }} item>
        <FabricItemInfoTab index={key} cardItem={value.product} isWatchList />
      </Grid>
    ));
  }

  return (
    <>
      <Dialog maxWidth={maxWidth} open onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">
          {t('watchlist')}
          <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {watchMap.size > 0 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginBottom: '20px',
                }}
              >
                <Button style={{ textTransform: 'capitalize' }} type="button" onClick={deleteAllPaginationCards}>
                  Entfernen
                </Button>
                <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
                  PDF-Download
                </Button>
                <Button type="button" onClick={() => {}}>
                  <CustomerInquiryDialog margin="false" />
                </Button>
              </div>
              <Grid container spacing={2}>
                <AddCardTypesItems />
              </Grid>
            </>
          ) : (
            <div> {t('empty_watchlist_text')} </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

WatchListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { useNavigate } from 'react-router-dom';
import { hasKeyInLocalStorage } from '../../util/util';

const ITEM_HEIGHT = 48;

export default function HamburgerMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuName, setMenuName] = useState('');
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const options = ['Design Selector - SONNENSCHUTZSTOFFE', 'STOFFE VERGLEICHEN', 'SCHATTENSIMULATOR'];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const redirect = (value) => {
    switch (value) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/compare');
        break;
      case 2:
        navigate('/shadow');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const name = hasKeyInLocalStorage('burgerMenuName') ? localStorage.getItem('burgerMenuName') : options[0];
    if (!localStorage.getItem('burgerMenuName'))
      localStorage.setItem('burgerMenuName', 'Design Selector - SONNENSCHUTZSTOFFE');
    setMenuName(name);
  }, [options]);

  const handleClose = (event) => {
    setAnchorEl(null);
    if (event.target.value || event.target.value === 0) {
      setSelected(event.target.value);
      localStorage.setItem('burgerMenuName', options[event.target.value]);
      redirect(event.target.value);
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '100%',
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem selected={selected} key={option} value={index} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <b>{menuName}</b>
    </div>
  );
}

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import * as PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import FabricWatchList from './FabricWatchList';
import { maxWidthTablet, minWidthDesktop, minWidthTablet } from '../../util/util';

const ValidatedTextField = ({ isInvalid, onSubmitted, ...props }) => {
  const { helperText, value } = props;
  let invalid = isInvalid(value);
  if (onSubmitted) {
    invalid = false;
  }

  return <TextField {...props} error={Boolean(invalid)} helperText={invalid || helperText} />;
};

const ValidatedTextMultiTextField = ({ isInvalid, onSubmitted, ...props }) => {
  const { helperText, value } = props;
  let invalid = isInvalid(value);
  if (onSubmitted) {
    invalid = false;
  }

  return (
    <TextField
      multiline
      maxLength="500"
      rows={4}
      {...props}
      error={Boolean(invalid)}
      helperText={invalid || helperText}
    />
  );
};

ValidatedTextField.propTypes = {
  isInvalid: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onSubmitted: PropTypes.bool.isRequired,
};

const CustomerInquiryForm = forwardRef((props, ref) => {
  const isDesktop = useMediaQuery({ minWidth: minWidthDesktop });
  const isTabletHigh = useMediaQuery({ minWidth: 800, maxWidth: maxWidthTablet });
  const isTabletLow = useMediaQuery({ minWidth: minWidthTablet, maxWidth: 800 });
  const isPhoneHigh = useMediaQuery({ minWidth: 699, maxWidth: minWidthTablet });
  const isPhoneLow = useMediaQuery({ minWidth: 0, maxWidth: 699 });
  const { t } = useTranslation();

  let numberOfTodos;
  if (isDesktop) {
    numberOfTodos = 6;
  } else if (isTabletHigh) {
    numberOfTodos = 5;
  } else if (isTabletLow) {
    numberOfTodos = 4;
  } else if (isPhoneHigh) {
    numberOfTodos = 3;
  } else if (isPhoneLow) {
    numberOfTodos = 2;
  }
  const { watchMap } = useData();
  const { cardId, onSubmit } = props;
  const [privacyPolicyState, setPrivacyPolicyState] = useState(false);
  const [notSubmitted, setSubmitted] = useState(true);

  /* const getCurrentLink = () => {
    if (localURI) {
      return `${localURI}?userId=${userId}&hs1=${selectedItems[0].fabric.post_title}&hs2=${selectedItems[1].fabric.post_title}&hs3=${selectedItems[2].fabric.post_title}&scene=${selectedSceneIndex}`;
    }
    return `${window.location.href}?userId=${userId}&hs1=${selectedItems[0].fabric.post_title}&hs2=${selectedItems[1].fabric.post_title}&hs3=${selectedItems[2].fabric.post_title}&scene=${selectedSceneIndex}`;
  }; */
  const [inputs, setInputs] = useState([
    {
      id: 'email',
      label: t('enter_email'),
      placeholder: 'john@acme.com',
      value: '',
      helperText: t('enter_email'),
      fullWidth: true,
      valid: false,
      isInvalid: (value) =>
        /\S+@\S+\.\S+/.test(value) ? false : 'Hoppla. Die Email Adresse scheint nicht gültig zu sein.',
    },
    {
      id: 'firstName',
      label: t('enter_firstname'),
      placeholder: t('enter_firstname'),
      value: '',
      helperText: t('enter_firstname'),
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : 'Das Feld darf nicht leer sein.'),
    },
    {
      id: 'lastName',
      label: t('enter_lastname'),
      placeholder: t('enter_lastname'),
      value: '',
      helperText: t('enter_lastname'),
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : 'Das Feld darf nicht leer sein.'),
    },
    {
      id: 'regarding',
      label: t('enter_subject'),
      placeholder: t('enter_subject'),
      value: 'Stoffanfrage',
      helperText: t('enter_subject'),
      fullWidth: true,
      valid: true,
      isInvalid: (value) => (value !== '' ? false : 'Das Feld darf nicht leer sein.'),
    },
    {
      id: 'note',
      label: t('enter_note'),
      placeholder: t('enter_note'),
      value: '',
      multiLine: true,
      rows: 4,
      helperText: t('enter_note'),
      fullWidth: true,
      valid: false,
      isInvalid: (value) => (value !== '' ? false : 'Das Feld darf nicht leer sein.'),
    },
  ]);

  const checkBoxValidationStyles = {
    error: {
      color: 'red',
      fontSize: 'small',
    },
  };

  function allFieldsValid() {
    for (let index = 0; index < inputs.length; index += 1) {
      if (!inputs[index].valid) return false;
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    submit() {
      setSubmitted(false);
      if (allFieldsValid()) onSubmit(inputs);
    },
  }));

  const divStyles = {
    color: '#707070',
  };

  const onChange = ({ target: { id, value } }) => {
    const newInputs = [...inputs];
    const index = inputs.findIndex((input) => input.id === id);

    const { isInvalid } = newInputs[index];

    newInputs[index] = {
      ...inputs[index],
      value,
      valid: !isInvalid(value),
    };

    setInputs(newInputs);
  };

  /* let listItems;
  if (cardId === -1) {
    listItems = Array.from(watchMap).map((element) => <li key={element[0]}>{`${element[1].product.post_title} `}</li>);
  } else {
    listItems = Array.from(watchMap).map((element) => <li key={element[0]}>{`${element[1].product.post_title} `}</li>);
  } */

  return (
    <>
      <form action="">
        <fieldset style={{ borderRadius: 10 }}>
          <div style={divStyles}>{t('copy_text')}</div>
          <ValidatedTextField {...inputs[0]} onChange={onChange} onSubmitted={notSubmitted} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ValidatedTextField
              {...inputs[1]}
              onChange={onChange}
              onSubmitted={notSubmitted}
              style={{ marginRight: '30px' }}
            />
            <ValidatedTextField {...inputs[2]} onChange={onChange} onSubmitted={notSubmitted} />
          </div>
          <ValidatedTextField {...inputs[3]} onChange={onChange} onSubmitted={notSubmitted} />
          <ValidatedTextMultiTextField {...inputs[4]} onChange={onChange} onSubmitted={notSubmitted} />
          <p style={divStyles}>{t('fabric_request')}</p>
          <FabricWatchList numberOfElements={numberOfTodos} cardId={cardId} />
          <FormGroup row style={{ marginTop: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyPolicyState}
                  onChange={(event) => setPrivacyPolicyState(event.target.checked)}
                  name="privacy_policy_checkbox"
                  color="primary"
                  required
                />
              }
              label={
                <a href="https://sattler-backend-dev.datengarage.com/datenschutzerklarung">Datenschutzerklärung</a>
              }
            />
          </FormGroup>
          <div>
            {!notSubmitted && !privacyPolicyState && (
              <span style={checkBoxValidationStyles.error}>Sie müssen der Datenschutzerklärung zustimmen!</span>
            )}
          </div>
        </fieldset>
      </form>

      {/* <fieldset style={{ borderRadius: 10, marginTop: 30 }}>
        <legend>{t('preview_message')}</legend>
        <div style={{ marginLeft: 30 }}>
          <h5>{t('subject')} Stoffanfrage</h5>
          <p>Liebes [Sattler, Warema, Wo&Wo]-Team,</p>
          <p>ich interessiere mich für folgende Stoffe:</p>
            <ul>{hasNoFabric ? <div style={{ color: 'red' }}>{t('minimum_fabric_text')}</div> : listItems}</ul> 
        <p>
          Diese Anfrage wurde über den Design Selector auf folgender Seite erstellt:
          <br />
          <a href={getCurrentLink()}>{getCurrentLink()} </a>
        </p>
      </fieldset> */}
    </>
  );
});

export default CustomerInquiryForm;

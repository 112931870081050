import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchBar from 'material-ui-search-bar';
import { Button, Container, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ColorFilter from './ColorFilter';
import FabricPagination from './FabricPagination';
import CustomerInquiryDialog from './CustomerInquiryDialog';
import FabricWatchList from './FabricWatchList';
import { useData } from '../DataProvider';
import FilterOptionsAccordion from './FilterOptionsAccordion';
import api from '../../api/api';
import { IsPhoneLandScape, IsPhonePortrait, IsTabletLandScape, IsTabletPortrait } from '../../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsRoot: {
    minHeight: 65,
    height: 65,
    [theme.breakpoints.down('sm')]: {
      height: 90,
    },
    textTransform: 'capitalize',
  },
  tabRoot: {
    minHeight: 65,
    height: 65,
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container className="tabContainer">
          <Box p={3}>
            <Typography component="span" variant="body2">
              {children}
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FabricTabPane({
  index = 0,
  hotspotId,
  hotspotIndex,
  isArticleSearch,
  isCompareMode,
  productGroupId,
  userId,
  setId,
}) {
  const isPortraitPhone = IsPhonePortrait();
  const isPhoneLandScape = IsPhoneLandScape();
  const isTabletLandScape = IsTabletLandScape();
  const isTabletPortrait = IsTabletPortrait();
  const [tabValue, setTabValue] = useState(index);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [shouldReset, setReset] = useState(false);
  const [isDraggable, setDraggable] = useState(false);
  const [shouldResetFilter, setShouldResetFilter] = useState(false);
  const [expandedId, setExpandedId] = useState();
  const { watchMap, selectColor } = useData();
  const classes = useStyles({ selectColor });
  const { t } = useTranslation();
  const [numberOfElements, setNumberOfElements] = useState(10);

  const handleChange = (event, newValue) => {
    setShouldResetFilter(true);
    setTabValue(newValue);
  };

  const downloadPDF = async () => {
    const pdfParams = {
      isWatchList: true,
      userId,
      fabricIds: Array.from(watchMap).map(([, value]) => value.product.id),
      setId,
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    window.open(url);
  };

  function updateNumberOfElements(newValue) {
    setNumberOfElements(newValue);
  }

  function a11yProps(tabIndex) {
    return {
      id: `simple-tab-${tabIndex}`,
      'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
  }

  function containsTextFilter(mapItem) {
    if (mapItem) {
      return mapItem.product.title.indexOf(searchBarValue.replace(/ /g, '')) !== -1;
    }
    return true;
  }

  function showNumberOfItems() {
    if (watchMap.size > 0) return `(${watchMap.size})`;
    return '';
  }

  useEffect(() => {
    if (isPhoneLandScape) setNumberOfElements(8);
    if (isTabletPortrait) setNumberOfElements(8);
    if (isPortraitPhone) setNumberOfElements(4);
    if (isTabletLandScape) setNumberOfElements(10);
  }, [isPhoneLandScape, isPortraitPhone, isTabletLandScape, isTabletPortrait]);

  return (
    <Paper square className={clsx(classes.root)}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="#efc743"
        textColor="#efc743"
        aria-label="icon tabs example"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
        }}
      >
        <Tab
          classes={{
            root: classes.tabsRoot,
          }}
          label={t('filter')}
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: classes.tabsRoot,
          }}
          label={t('search')}
          icon={<SearchIcon />}
          aria-label="search"
          {...a11yProps(1)}
        />
        <Tab
          classes={{
            root: classes.tabsRoot,
          }}
          label={`${t('watchlist')} ${showNumberOfItems()}`}
          icon={<FavoriteIcon style={{ color: selectColor }} />}
          aria-label="favorite"
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ColorFilter />
        <Divider light />
        <FilterOptionsAccordion
          numberItems={numberOfElements}
          updateNumberOfElements={updateNumberOfElements}
          expandedId={expandedId}
          setExpandedId={setExpandedId}
          resetFilter={shouldResetFilter}
        />
        <Divider light />
        <FabricPagination
          numberofFabrics={numberOfElements}
          hotspotId={hotspotId}
          searchBarValue={searchBarValue}
          hotspotIndex={hotspotIndex}
          isArticleSearch={isArticleSearch}
          isCompareMode={isCompareMode}
          subgroupId={productGroupId}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SearchBar
          value={searchBarValue}
          onChange={(newValue) => {
            setReset(true);
            setSearchBarValue(newValue.toLowerCase());
          }}
          onRequestSearch={() => {}}
          placeholder={t('enter_search')}
          onCancelSearch={() => setSearchBarValue('')}
          autoFocus
        />
        <FabricPagination
          filter={containsTextFilter}
          resetCounter={shouldReset}
          setReset={setReset}
          numberofFabrics={numberOfElements}
          searchBarValue={searchBarValue}
          isArticleSearch={isArticleSearch}
          isSearchBar
          hotspotId={hotspotId}
          isCompareMode={isCompareMode}
          subgroupId={productGroupId}
          hotspotIndex={hotspotIndex}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {watchMap.size > 0 ? (
          <>
            <FabricWatchList
              numberOfElements={numberOfElements}
              isDraggable={isDraggable}
              subgroupId={productGroupId}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant={isDraggable ? 'outlined' : 'normal'}
                style={{ marginLeft: isPortraitPhone ? 20 : 40, marginTop: 25, textTransform: 'capitalize' }}
                onClick={() => setDraggable(!isDraggable)}
              >
                Priorisieren
              </Button>

              <Button
                style={{
                  textTransform: 'capitalize',
                  marginLeft: isPortraitPhone ? 20 : 40,
                  marginTop: 25,
                }}
                onClick={downloadPDF}
              >
                PDF-Download
              </Button>
              <CustomerInquiryDialog margin="true" />
            </div>
          </>
        ) : (
          <div>{t('empty_watchlist_text')}</div>
        )}
      </TabPanel>
    </Paper>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import HamburgerMenu from './HamburgerMenu';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  navLink: {
    color: '#414141',
    textDecoration: 'none',
    fontSize: '20px',
    '@media (max-width:1240px)': {
      fontSize: '18px',
    },
    '@media (max-width:780px)': {
      fontSize: '15px',
    },
    '@media (max-width:640px)': {
      fontSize: '12px',
    },
  },
  bgHeader: {
    background: '#F8F8F8',
  },
  linkStyles: {
    fontWeight: '600',
  },
  alignRight: {
    textAlign: 'right',
  },
}));

export default function NavigationBar() {
  const { t } = useTranslation();

  const classes = useStyles();
  const { userId } = useData();
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 960 });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const [linkStatus, setLinkStatus] = useState();

  const Portrait = ({ children }) => {
    return isPortrait ? children : null;
  };

  const Landscape = ({ children }) => {
    return isLandscape ? children : null;
  };

  const handleClick = (index) => {
    setLinkStatus(Number(index));
    localStorage.setItem('activePage', index);
  };

  useEffect(() => {
    const index = localStorage.getItem('activePage') || 0;
    setLinkStatus(Number(index));
  }, [setLinkStatus]);

  return (
    <>
      <Portrait>
        <HamburgerMenu />
      </Portrait>
      <Landscape>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.bgHeader}>
          <Grid item xs={6}>
            <Box p={1}>
              <Link
                className={clsx(classes.navLink, linkStatus === 0 && classes.linkStyles)}
                to={`/${userId ? `?userId=${userId}` : ''}`}
                onClick={() => handleClick(0)}
              >
                <div style={isPhone && isLandscape ? { fontSize: 12 } : null}>
                  Design Selector -{' '}
                  <div style={{ display: 'inline-block', textTransform: 'uppercase' }}>{t('fabrics')}</div>
                </div>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.box" p={1} className={!isMobile ? classes.alignRight : null}>
              <Link
                className={clsx(classes.navLink, linkStatus === 1 && classes.linkStyles)}
                to={`/compare${userId ? `?userId=${userId}` : ''}`}
                style={{ textTransform: 'uppercase' }}
                onClick={() => handleClick(1)}
              >
                {t('fabric_compare')}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.box" p={1} className={!isMobile ? classes.alignRight : null}>
              <Link
                className={clsx(classes.navLink, linkStatus === 2 && classes.linkStyles)}
                to={`/shadow${userId ? `?userId=${userId}` : ''}`}
                style={{ textTransform: 'uppercase' }}
                onClick={() => handleClick(2)}
              >
                {t('shadow_sim')}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Landscape>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
    borderColor: (props) => (props.isSelected ? props.borderColor : 'transparent'),
    borderStyle: 'solid',
  },
  media: {
    height: 60,
    width: 110,
    margin: 13,
  },
  div: {
    marginLeft: 12,
    marginBottom: 7,
  },
});

export default function MuiCardSceneSelection({
  item,
  selectedValue,
  updateSceneSelection,
  borderColor,
  productGroupIndex,
}) {
  const imageUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${item.scene.sub_path}${item.scene.post_title}_preview.png`;
  const [isSelected, setIsSelected] = useState(selectedValue);
  const classes = useStyles({ borderColor, isSelected });
  const sceneId = item.scene.id;

  const handleClick = () => {
    const invertedSelect = !isSelected;
    setIsSelected(invertedSelect);
    updateSceneSelection({ sceneId, isSelected: invertedSelect }, productGroupIndex);
  };

  useEffect(() => {
    setIsSelected(selectedValue);
  }, [selectedValue]);

  return (
    <Card className={classes.root} onClick={handleClick}>
      <CardActionArea>
        <CardMedia className={classes.media} image={imageUrl} title={item.scene_type_name} />
        <div className={classes.div}>{item.scene.post_title}</div>
      </CardActionArea>
    </Card>
  );
}

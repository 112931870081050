import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import ColorPicker, { toColor } from 'react-color-palette';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { getTriggerOpenDialog } from '../Subjects/subjects';

import { useData } from '../DataProvider';

export default function WallViewDialog({ left, top, isSpecial, orientation, isCompareMode }) {
  const [open, setOpen] = useState(false);
  const [isHovering, setHovering] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [colors, setColors] = useState([]);
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const maximalWidth = isPhone ? 'xs' : 'md';
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const maxWidth = 1232;

  const { t } = useTranslation();
  const { setWallColor, setWallColorRight, wallColor, wallColorRight, selectColor, jsonData, isFullScreen } = useData();

  const calculatePositions = useCallback(() => {
    let posX;
    let posY;
    const { innerWidth: width, innerHeight } = window;

    let height = 700;

    if (width < 699) {
      height = 300;
    }
    if (width < 900 && innerHeight < 699) {
      height = 344;
    }

    let windowWidth = width > maxWidth ? maxWidth : width;
    if (isFullScreen) {
      height = 880;
      windowWidth = width;
    }

    if (width > innerHeight && width < 1250 && width > 1000) {
      windowWidth -= 100;
    }
    const actualWidth = (height * 16) / 9;
    const offset = (actualWidth - windowWidth) / 2;

    posX = actualWidth * left - offset;

    posY = height * top;

    if (width > 750) {
      posY -= 25;
      posX -= 25;
    }

    if (isFullScreen) {
      posY += 25;
      posX += 25;
    }

    if (posX < 0) {
      // posX = -2000;
    }

    if (posY < 0) {
      // posY = -2000;
    }

    return { posX, posY };
  }, [isFullScreen, left, top]);

  useEffect(() => {
    const colorData = ['#ef4343', '#ef9943', '#7d5023', '#176b10', '#a2a2a2', '#efe443'];

    const wallColors = jsonData.wallcolor_option.split('\r\n');
    if (wallColors.length > 0) {
      setColors([...wallColors]);
    } else {
      setColors([...colorData]);
    }
  }, [jsonData.wallcolor_option, isFullScreen]);

  useEffect(() => {
    function handleResize() {
      const positions = calculatePositions(true);
      setPositionX(positions.posX);
      setPositionY(positions.posY);
    }

    const positions = calculatePositions();
    setPositionX(positions.posX);
    setPositionY(positions.posY);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculatePositions, isFullScreen, positionX]);

  const buttonClassLeft = {
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: 10,
    marginRight: 20,
  };

  const itemStyle = {
    textAlign: 'center',
    width: 30,
    height: 0,
  };

  const closeButton = {
    position: 'absolute',
    right: 0,
    top: 1,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  getTriggerOpenDialog().subscribe(() => {
    if (isSpecial) {
      handleClickOpen();
    }
  });

  const buttonStyleLeft = {
    position: 'absolute',
    left: '-18vh',
    background: `0% 0% no-repeat padding-box padding-box ${selectColor}`,
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
    textAlign: left,
    paddingLeft: '8px',
    height: '40px',
    width: '200px',
    fontSize: ' max(0.7vw, 0.7rem)',
  };

  const buttonLabelStyle = {
    position: 'absolute',
    left: positionX || 0,
    top: positionY || 0,
    width: '15%',
    zIndex: 1,
  };

  const buttonStyleRight = {
    position: 'absolute',
    background: backgroundColor,
    width: '100%',
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    justifyContent: 'space-between',
    textAlign: 'right',
    minWidth: 200,
    fontSize: ' 12px',
    zIndex: -1,
    height: 40,
    paddingRight: '8px',
    top: 0,
  };

  function handleColor(newColor) {
    if (isCompareMode) setWallColorRight(newColor);
    else {
      setWallColor(newColor);
      setWallColorRight(newColor);
    }
  }

  const handleMouseEnter = () => {
    setBackgroundColor('#FFFFFFCC 0% 0% no-repeat padding-box');
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setBackgroundColor('transparent');
    setHovering(false);
  };

  return (
    <>
      <div className="hotspot" style={buttonLabelStyle} onMouseLeave={handleMouseLeave}>
        {orientation === 'left' && isHovering && (
          <div style={buttonStyleLeft}>
            <div>
              <div style={{ marginTop: 10, lineHeight: '1.5em', height: '3em', overflow: 'hidden', marginLeft: 40 }}>
                <b>{t('wallcolor')}</b>
              </div>
            </div>
          </div>
        )}
        <div className="button-wrap">
          <button type="button" className="pulse-button-wall" onClick={handleClickOpen} onMouseEnter={handleMouseEnter}>
            +
          </button>
        </div>
        {orientation === 'right' && isHovering && (
          <div style={buttonStyleRight}>
            <div style={{ marginTop: 5, lineHeight: '1.5em', height: '3em', overflow: 'hidden', marginLeft: 40 }}>
              <b>{t('wallcolor')}</b>
            </div>
          </div>
        )}
      </div>
      <Dialog maxWidth={maximalWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle style={{ textTransform: 'uppercase' }} id="max-width-dialog-title">
          {t('wallcolor')}
        </DialogTitle>
        <DialogContent>
          <IconButton style={closeButton} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <ColorPicker
            width={isPhone ? 300 : 456}
            height={isPhone ? 150 : 210}
            color={isCompareMode ? wallColorRight : wallColor}
            onChange={handleColor}
          />
          <Grid container spacing={2} justify="center" direction="row">
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                {colors.map((color) => (
                  <div style={buttonClassLeft} key={color}>
                    <div style={itemStyle}>
                      <svg height="100" width="100" onClick={() => handleColor(toColor('hex', color))}>
                        <circle stroke="#b9aea5" strokeWidth="1" cx="20" cy="20" r="10" fill={color} />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

WallViewDialog.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  isSpecial: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  isCompareMode: PropTypes.bool,
};

WallViewDialog.defaultProps = {
  isSpecial: false,
  isCompareMode: false,
  top: 0,
  left: 0,
};

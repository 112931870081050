import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import { getCollectionById, getProductGroupById } from '../../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'contents',
  },
  details: {
    font: ' normal normal normal 14px/19px Open Sans',
    color: '#414141',
  },
}));

const QualityAccordion = ({ setDesignCount, expandedId, setExpandedId }) => {
  const classes = useStyles();
  const { jsonData, filterItems, setFilterItems, currentScene } = useData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [countElements, setCountElements] = useState(0);
  const [relationKeys, setRelationKeys] = useState([]);
  const { collectionFilter, designFilter } = filterItems;
  const { t } = useTranslation();
  const id = 0;

  const handleChangeCollection = (collection) => {
    const collectionId = collection.id.toString();
    const relatedDesignLine = currentScene.relation_collection_design[collectionId].filter((elem) => elem !== '');
    if (collectionFilter.includes(collectionId)) {
      filterItems.collectionFilter = collectionFilter.filter((c) => c !== collectionId);

      filterItems.designFilter = designFilter.filter((value) => value !== relatedDesignLine);
    } else {
      collectionFilter.push(collectionId);
      filterItems.collectionFilter = collectionFilter;
    }
    if (relatedDesignLine) {
      relatedDesignLine.forEach((designLine) => {
        if (!designFilter.includes(designLine) && filterItems.collectionFilter.includes(collectionId)) {
          designFilter.push(designLine);
        } else {
          const index = designFilter.indexOf(designLine);
          if (index > -1) {
            designFilter.splice(index, 1);
          }
        }
      });
      filterItems.designFilter = designFilter;
      setFilterItems({ ...filterItems });
    }
    setDesignCount(designFilter.length);
    setCountElements(collectionFilter.length);
  };

  function handleOnChange(expanded) {
    setIsExpanded(expanded);
    setExpandedId(id);
  }

  function isCollectionChecked(collection) {
    return collectionFilter.includes(collection.id.toString());
  }

  useEffect(() => {
    const productGroup = getProductGroupById(currentScene.product_group, jsonData);
    if (productGroup.isGardenFurniture !== '') {
      const relations = Object.keys(currentScene.relation_collection_design).reduce((acc, item) => {
        const relation = item.split(',');
        return [...acc, relation];
      }, []);

      const rKeys = [...new Set(relations.flat())];
      setRelationKeys(rKeys);
    } else {
      setRelationKeys(Object.keys(currentScene.relation_collection_design));
    }
  }, [currentScene.product_group, currentScene.relation_collection_design, jsonData]);

  useEffect(() => {
    if (expandedId !== id) setIsExpanded(false);
    setCountElements(collectionFilter.length);
  }, [collectionFilter.length, expandedId, filterItems]);

  return (
    <div className={classes.root}>
      <Accordion onChange={(event, expanded) => handleOnChange(expanded)} expanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>
            <Grid container>
              <Grid item xs={11}>
                {t('quality')}
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                ({countElements})
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item spacing={1}>
            <Grid item>
              <Grid container>
                {relationKeys.map((collectionId) => {
                  const collection = getCollectionById(collectionId, jsonData);
                  return (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={collection.post_title}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCollectionChecked(collection)}
                            onChange={() => handleChangeCollection(collection)}
                            name="checked"
                            color="primary"
                            key={collection.post_title}
                          />
                        }
                        label={collection.post_title}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default QualityAccordion;

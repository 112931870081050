import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ScenePagination from './ScenePagination';
import { maxWidthTablet, minWidthDesktop, minWidthTablet } from '../../../util/util';

export default function Thumbs({ close }) {
  const isDesktop = useMediaQuery({ minWidth: minWidthDesktop });
  const isTabletHigh = useMediaQuery({ minWidth: 800, maxWidth: maxWidthTablet });
  const isTabletLow = useMediaQuery({ minWidth: minWidthTablet, maxWidth: 800 });
  const isPhoneHigh = useMediaQuery({ minWidth: 699, maxWidth: minWidthTablet });
  const isPhoneLow = useMediaQuery({ minWidth: 0, maxWidth: 500 });
  const { t } = useTranslation();

  // let height = 0;
  let numberOfTodos;
  if (isDesktop) {
    numberOfTodos = 4;
  } else if (isTabletHigh) {
    numberOfTodos = 3;
  } else if (isTabletLow) {
    numberOfTodos = 2;
  } else if (isPhoneHigh) {
    numberOfTodos = 2;
    // height = 160;
  } else if (isPhoneLow) {
    numberOfTodos = 2;
    // height = 200;
  }
  const thumbStyles = {
    background: 'white',
    textAlign: 'left',
    textTransform: 'uppercase',
    margin: isPhoneLow ? '0 20px 0 20px' : 40,
    zIndex: 999,
  };
  return (
    <div style={thumbStyles}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          className="MuiTypography--heading"
          variant="h6"
          gutterBottom
          style={{ padding: '10px 10px 10px 10px' }}
        >
          {t('scene_selection')}
        </Typography>
        <IconButton aria-label="close" onClick={close} style={{ height: 50 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <ScenePagination todosPerPage={numberOfTodos} />
    </div>
  );
}

Thumbs.propTypes = {
  close: PropTypes.func.isRequired,
};

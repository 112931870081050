import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Button, Checkbox, Container, FormControlLabel, Grid, Snackbar, TextField } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import FormData from 'form-data';
import InputFieldWithCopyButton from './InputFieldWithCopyButton';
import api from '../../api/api';
import { useData } from '../DataProvider';

const tabHeight = '35px';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    textTransform: 'capitalize',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ShareTabPane({ localCardId, cardItem }) {
  const subjectText = 'Empfehlung eines Stoffes';
  const { selectColor } = useData();
  const classes = useStyles({ selectColor });
  const [receiverEmail, setReceiverEmail] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [name, setName] = useState('');
  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] = useState(false);
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(false);

  const messageText = `Ich habe soeben den Stoff ${cardItem.post_title} im Design Selector gefunden. Klicken Sie auf den Link, um ihn anzusehen:`;
  const termOfUseText = (
    <span>
      Ich habe die <a href="https://sattler-backend-dev.datengarage.com/datenschutzerklarung">Datenschutzerklärung </a>
      gelesen und erkläre mich einverstanden, dass meine angegebenen personenbezogenen Daten laut Datenschutzerklärung
      verarbeitet werden. Die Erteilung meiner Einwilligung erfolgt freiwillig und ich kann diese Erklärung jederzeit
      schriftlich widerrufen. Mir ist bewusst, dass bereits gesendete Empfehlungen nicht zurückgezogen werden können.
    </span>
  );
  const [message, setMessage] = useState(messageText);
  const [subject, setSubject] = useState(subjectText);
  const sendEmailRequest = async () => {
    const data = new FormData();
    data.append('post_id', '1');
    data.append('receiver_email', receiverEmail);
    data.append('sender_email', senderEmail);
    data.append('type', 'share');
    data.append('subject', subject);
    data.append('sender_name', name);
    data.append('text', message);
    data.append('link', link);

    const response = await api.sendEmailRequest(data);
    if (response.status === 200) {
      setResponseState({ isError: false, msg: 'Email has been sent.' });
      setOpen(true);
    } else {
      setResponseState({
        isError: true,
        msg: `There has been an error occurred. Response status:
      ${response.status}`,
      });
    }
  };

  return (
    <Paper square>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container justify="space-between">
          <Grid xs={12} md={5}>
            <b>Absender</b>
            <TextField
              id="outlined-basic"
              style={{ width: '100%' }}
              label="E-Mail Adresse*"
              variant="outlined"
              type="email"
              onChange={(e) => setSenderEmail(e.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid xs={12} md={5}>
            <b>Empfänger</b>
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              label={`${t('email')} * `}
              variant="outlined"
              type="email"
              fullWidth
              onChange={(e) => setReceiverEmail(e.target.value)}
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label={t('name')}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            className={classes.textField}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label={t('subject')}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className={classes.textField}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Nachricht"
            variant="outlined"
            multiline
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.fontSize,
              },
            }}
          />
          <InputFieldWithCopyButton setLink={setLink} localCardId={localCardId} />
          <FormControlLabel
            style={{ margin: 30 }}
            control={
              <Checkbox
                fullWidth
                checked={checkboxState}
                onChange={(event) => setCheckboxState(event.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <Trans
                i18nKey="data_privacy"
                t={t}
                components={[<a href={`${process.env.REACT_APP_BASE_URL}datenschuzerklaerung`}>Datenschuzerklärung</a>]}
              />
            }
          />
        </Grid>
        <Grid xs={12} md={12} style={{ textAlign: 'right' }}>
          <Button variant="contained" color="secondary" onClick={() => sendEmailRequest()}>
            Senden
          </Button>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
      />
    </Paper>
  );
}

ShareTabPane.propTypes = {
  close: PropTypes.func.isRequired,
};
